.banner06{
  padding: 80px 0 70px;
  position: relative;
  z-index: 1;
  box-shadow: 0 10px 25px 0 rgba(0, 0, 0, .5);
  background-image: url("../images/banners/banner06/bg-children.jpg");
  background-position: top;
  background-repeat: no-repeat;
  background-size: auto 100%;
  background-position: center center;
  object-fit: cover;
  object-position: 0 0;
  @media only screen and (max-width: 767px) {
    background-image: url("../images/banners/banner06/bg-children_sp.jpg");
    background-size: 100% auto;
  }
  @media only screen and (min-width: 1800px) {
    background-size: auto;
  }

  &__box{
    margin: 180px auto 0;
    h2{
      font-weight: bold;
      width: 100%;
      padding: 0.5em 0;
      font-size: 30px;
      text-align: center;
      background: rgba(255, 255, 255, 0.623);
      letter-spacing: 0.125em;
      color: var(--colorGray);
      border-radius: 10px;
      max-width: 1000px;
      @media only screen and (max-width: 767px) {
        font-size: 20px;
      }
      span{

      }
    }


    .Courses{
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-wrap: wrap;
      position: relative;
      margin: 0 auto 170px;
      @media only screen and (max-width: 767px) {
        margin: 0 auto 110px;
      }
      .CoursesItem{
        width: 100%;
        max-width: 500px;
        min-height: 750px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
        border-radius: 15px;
        padding: 50px 20px;
        @media only screen and (max-width: 767px) {
          min-height: auto;
        }
      }
      h3{
        text-align: center;
        color: var(--colorGray);
        line-height: 2.0em;
        span{
          line-height: 1.5em;
        }
        .upper{
          font-size: 24px;
          @media only screen and (max-width: 767px) {
            font-size: 18px;
          }
        }
        .bottom{
          font-size: 32px;
          @media only screen and (max-width: 767px) {
            font-size: 24px;
          }
          .large-text{
            font-size: 1.2em;
          }
        }
        .Balls{
          margin: 1.75em;
          display: flex;
          justify-content: center;
          align-items: center;
          @media only screen and (max-width: 767px) {
            margin: 1.0em;
          }
          .ball{
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background: white;
            margin: 0 10px;
            @media only screen and (max-width: 767px) {
              margin: 0px 4px;
              width: 6px;
              height: 6px;
            }
          }
        }
      }
      h4{
        margin: 0.75em 0 1em;
        text-align: center;
      }
      .marker{
        text-align: center;
        font-size: 21px;
        line-height: 3.25em;
        background: linear-gradient(transparent 70%, var(--mainBGColorYellow) 0%);
        @media only screen and (max-width: 767px) {
          font-size: 18px;
        }
      }
      p{
        line-height: 2.0em;
        font-size: 22px;
        margin: 0 0 1em;
        text-align: center;
        width: 93%;
        @media only screen and (max-width: 767px) {
          font-size: 14px;
        }
      }
      .TextJustify{
        text-align: justify;
      }
      .graph{
        display: block;
        margin: 1em auto 1em;
        width: 100%;
        max-width: 330px;
        @media only screen and (max-width: 767px) {
          max-width: 240px;
        }
      }

      .notice-Visibility{
        opacity: 0;
        font-size: 12px;
        margin: 0;
        text-align: justify;
        @media only screen and (max-width: 767px) {
          opacity: 1;
        }
      }
      .notice{
        font-size: 16px;
        position: absolute;
        font-size: 16px;
        bottom: 2em;
        left: 0;
        right: 0;
        margin: 0;
        width: 100%;
        @media only screen and (max-width: 767px) {
          font-size: 12px;
        }
      }
    }

  }
  }