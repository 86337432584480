.FloatBanners {
	.pc-footer-line {
		position: fixed;
		right: 20px;
		bottom: 20px;
		z-index: 999;
	}
	.pc-footer-line img {
		width: 250px;
		height: 250px;
	}

	.aeon-banner {
		max-width: 840px;
	}

	@media only screen and (max-width: 500px) {
		.pc-footer-line {
			display: none;
		}
		.sp-footer-line {
			position: fixed;
			width: 100%;
			max-width: 480px;
			left: 50%;
			bottom: 0;
			transform: translate(-50%);
			z-index: 998;
		}
		.sp-footer-line img {
			width: 100%;
		}
		.aeon-banner {
			width: 100%;
		}
	}
	@media only screen and (min-width: 499px) {
		.sp-footer-line {
			display: none;
		}
	}
}
// フッターのイオンファンタジーの文字が見えるように
@media only screen and (max-width: 540px) {
	body {
		padding-bottom: 120px;
	}
}
