.Footer {
	font-size: 16px;
	color: #333;
	.copyright {
		font-size: 12px;
		text-align: center;
		padding: 40px 30px 0;
		@media only screen and (max-width: 1024px) {
			text-align: center;
			font-size: 10px;
		}
		@media only screen and (max-width: 599px) {
			text-align: center;
			font-size: 10px;
		}
	}
	a {
		color: #2088cb;
		font-weight: bold;
	}
	.hr {
		width: 100vw;
		height: 2px;
		background: #555;
	}
	.Label {
		margin: auto;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		padding: 100px 0 125px;
		img {
			display: inline-block;
			width: 190px;
		}
		@media only screen and (max-width: 1024px) {
			padding: 45px 0 50px;
			img {
				width: 320px;
			}
		}
		@media only screen and (max-width: 599px) {
			padding: 45px 0 50px;
			img {
				width: 190px;
			}
		}
		.label-list {
			margin: 2em auto 0;
			@media only screen and (max-width: 1024px) {
				font-size: 22px;
			}
			@media only screen and (max-width: 599px) {
				font-size: 16px;
			}
			&__item {
				font-weight: bold;
				line-height: 2em;
				text-align: center;
			}
		}
	}
	.Bottom {
		color: #02993b;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 45px 38px 50px;
		flex-direction: row;
		@media only screen and (max-width: 1024px) {
			flex-direction: column;
		}
		@media only screen and (max-width: 599px) {
			flex-direction: column;
		}
		.Left {
			img {
				height: 45px;
				margin: 0 25px 0;
			}
			:first-child {
				margin-left: 0;
			}
			@media only screen and (max-width: 1024px) {
				img {
					height: 25px;
				}
			}
			@media only screen and (max-width: 599px) {
				img {
					height: 25px;
				}
			}
		}
		.Right {
			margin: 20px auto 0px;
			.copyright {
				font-weight: bold;
				&_aeon {
					@media only screen and (max-width: 1024px) {
						font-size: 22px;
					}
					@media only screen and (max-width: 599px) {
						font-size: 10px;
					}
				}
			}
		}
	}
}
