.banner05 {
	background-color: #fff51e;
	padding: 80px 0 20px;
	position: relative;
	z-index: 5;
	box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.5);

	.triangle {
		position: absolute;
		z-index: 5;
		bottom: -90px;
		left: 0;
		right: 0;
		margin: auto;
		content: "";
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 90px 90px 0 90px;
		border-color: var(--mainBGColorYellow) transparent transparent transparent;
		filter: drop-shadow(2px 4px 2px rgba(0, 0, 0, 0.3));
		@media only screen and (max-width: 1024px) {
			bottom: -65px;
			border-width: 65px 75px 0 75px;
		}
	}

	&__box {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;

		p {
			line-height: 2em;
		}

		//大きいタイトル
		h2 {
			margin: 70px auto 55px;
			--H2TitleColor: #000;
			@media only screen and (max-width: 1024px) {
				font-size: 40px;
				margin: 15px auto 45px;
			}
			@media only screen and (max-width: 599px) {
				font-size: 24px;
				margin: 15px auto 45px;
			}
		}

		//タイトル
		h3 {
			text-align: center;
			font-size: 42px;
			font-weight: bold;
			color: var(--mainBGColorRed);
			margin: 0 auto 1em;
			@media only screen and (max-width: 1024px) {
				font-size: 36px;
				margin: 0 auto 1.5em;
			}
			@media only screen and (max-width: 599px) {
				font-size: 20px;
				margin: 0 auto 1.5em;
			}
		}

		//文章
		.Description {
			font-family: fot-tsukuardgothic-std, sans-serif;
			position: relative;
			font-size: 22px;
			margin: 0 auto 5em;
			text-align: center;
			@media only screen and (max-width: 1024px) {
				text-align: center;
				font-size: 27px;
				width: 85%;
			}
			@media only screen and (max-width: 599px) {
				text-align: justify;
				font-size: 14px;
			}
			.owl-man01 {
				position: absolute;
				top: -14em;
				left: -6em;
				width: 130px;
				@media only screen and (max-width: 1024px) {
					width: 50px;
					top: -14em;
					left: 0em;
				}
			}
			.owl-man02 {
				position: absolute;
				top: -5em;
				right: -6em;
				width: 120px;
				@media only screen and (max-width: 1024px) {
					width: 50px;
					top: -5em;
					right: -1em;
				}
			}
		}

		//矢印のリンク
		.LinkTo {
			font-size: 30px;
			text-decoration: underline;
			color: var(--mainBGColorRed);
			margin: 0 0 1em;
			position: relative;
		}
		@media only screen and (max-width: 1024px) {
			.LinkTo {
				font-size: 18px;
				line-height: 1.5em;
				display: flex;
				align-items: center;
				justify-content: center;
				text-align: center;
			}
		}

		//塊
		.BannerBoxItems {
			line-height: 2em;
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;
			// margin: 0 auto 0;
		}

		.lessons {
			.teachers {
				display: flex;
				justify-content: center;
				flex-wrap: wrap;
				margin: 4em auto 4em;
				gap: 210px;
				@media only screen and (max-width: 1024px) {
					align-items: baseline;
					margin: 4em auto 1em;
					gap: 120px;
					align-items: center;
				}
				@media only screen and (max-width: 599px) {
					align-items: baseline;
					margin: 4em auto 1em;
					gap: 50px;
					align-items: center;
				}
				&__image {
					margin: 15px 20px;
					position: relative;
					img {
						width: 100%;
						max-width: 300px;
						@media only screen and (max-width: 1024px) {
							max-width: 300px;
						}
						@media only screen and (max-width: 599px) {
							max-width: 145px;
						}
					}
					.picture {
						position: absolute;
						top: -135px;
						left: 40px;
						@media only screen and (max-width: 1024px) {
							top: -140px;
							left: 20px;
						}
						@media only screen and (max-width: 599px) {
							top: -60px;
							left: 20px;
						}
						&-right {
							position: absolute;
							left: 470px;
							@media only screen and (max-width: 1024px) {
								top: -110px;
								left: 290px;
							}
							@media only screen and (max-width: 599px) {
								top: -45px;
								left: 155px;
							}
						}
					}
				}
				&__profile {
					background-color: #fff;
					padding: 10px 80px 20px 80px;
					border-radius: 10px;
					width: 800px;
					font-family: fot-tsukuardgothic-std, sans-serif;
					@media only screen and (max-width: 1024px) {
						padding: 10px 20px 0 20px;
						max-width: 600px;
						width: 90vw;
					}
					@media only screen and (max-width: 599px) {
						padding: 10px 20px 0 20px;
						max-width: 313px;
					}
					&-belongs {
						line-height: 1.5em;
						font-size: 27px;
						@media only screen and (max-width: 1024px) {
							font-size: 36px;
						}
						@media only screen and (max-width: 599px) {
							font-size: 18px;
						}
					}
					&-position {
						font-size: 27px;
						@media only screen and (max-width: 1024px) {
							line-height: 1.5em;
							font-size: 27px;
						}
						@media only screen and (max-width: 599px) {
							line-height: 1.5em;
							font-size: 14px;
						}
						span {
							font-size: 27px;
							@media only screen and (max-width: 1024px) {
								font-size: 24px;
							}
							@media only screen and (max-width: 599px) {
								font-size: 13px;
							}
						}
					}
					&-title {
						font-size: 27px;
						@media only screen and (max-width: 1024px) {
							font-size: 27px;
						}
						@media only screen and (max-width: 599px) {
							font-size: 14px;
						}
					}
					&-name {
						font-size: 39px;
						line-height: 1.5em;
						@media only screen and (max-width: 1024px) {
							font-size: 50px;
						}
						@media only screen and (max-width: 599px) {
							font-size: 25px;
						}
						&-right {
							@media only screen and (min-width: 1024px) {
								line-height: 1.2em;
							}
						}
					}
					&-detail {
						text-align: center;
						position: relative;
						right: -150px;
						top: 10px;
						font-weight: 700;
						@media only screen and (max-width: 1024px) {
							top: 30px;
							right: -140px;
						}
						@media only screen and (max-width: 599px) {
							top: 10px;
							right: -75px;
						}
						&-left {
							right: 160px;
							@media only screen and (max-width: 1024px) {
								right: 150px;
							}
							@media only screen and (max-width: 599px) {
								right: 70px;
							}
						}
						p {
							font-weight: 700;
						}
					}
					&-PC-wrap {
						@media only screen and (min-width: 1024px) {
							display: flex;
							justify-content: center;
							align-items: center;
						}
					}
					&-message {
						position: relative;
						top: -10px;
						@media only screen and (max-width: 1024px) {
							top: -20px;
						}
						@media only screen and (max-width: 599px) {
							top: -10px;
						}
					}
					// アコーディオンパネル
					.acd-check {
						display: none;
					}
					.acd-label {
						margin-bottom: 1px;
						color: #eb4628;
						font-size: 26px;
						left: 345px;
						position: relative;
						top: 20px;
						line-height: 1.5em;
						@media only screen and (max-width: 1024px) {
							display: block;
							font-size: 32px;
							left: 0;
							top: 0;
						}
						@media only screen and (max-width: 599px) {
							font-size: 16px;
						}
						&-right {
							left: 40px;
							@media only screen and (max-width: 1024px) {
								text-align: end;
								left: 0;
							}
						}
					}
					.acd-content {
						// labelのmargin-rightと同値
						height: 0;
						opacity: 0;
						transition: all 0.5s ease-in-out;
						height: 0;
						visibility: hidden;
						overflow: hidden;
						p {
							color: var(--colorBlack);
							font-size: 25px;
							letter-spacing: 0.125em;
							line-height: 1.75em;
							text-align: justify;
							padding: 20px 0 0 0;
							@media only screen and (max-width: 1024px) {
								font-size: 26px;
								padding: 0;
							}
							@media only screen and (max-width: 599px) {
								font-size: 16px;
								padding: 0;
							}
						}
					}
					.acd-check:checked + .acd-label + .acd-content {
						height: 40px;
						margin: 1em 0 1em 0;
						opacity: 1;
						visibility: visible;
						overflow: hidden;
						height: auto;
					}
					.acd-check:checked + .acd-label {
						span {
							transform: rotate(180deg);
						}
					}
				}
			}

			.ce-button {
				width: 490px;
				margin: 0 auto 75px;
				img {
					width: 100%;
				}
				@media only screen and (max-width: 1024px) {
					width: 90vw;
					max-width: 450px;
					margin: 0 auto 60px;
					img {
						width: 100%;
					}
				}
				@media only screen and (max-width: 599px) {
					width: 90vw;
					max-width: 305px;
					margin: 0 auto 60px;
					img {
						width: 100%;
					}
				}
			}
		}

		.lesson-trainer {
			&__photos {
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: center;
				flex-wrap: wrap;
				margin: 4em auto 4em;
				img {
					display: block;
					width: 290px;
					&:nth-child(2n) {
						margin: 0 40px 0;
					}
				}
				@media only screen and (max-width: 1024px) {
					margin: 1em auto 1em;
					img {
						width: 145px;
						&:first-child {
							margin: 0 100% 0;
						}
						&:not(:first-child) {
							margin: 0 auto 0;
						}
					}
				}
			}
		}
	}
}
