.banner09{
  background: white;
  padding: 80px 0 70px;
  position: relative;
  z-index: 8;
  box-shadow: 0 10px 25px 0 rgba(0, 0, 0, .5);
  background-image: url("../images/banners/banner09/bg-owl-pc.svg");
  background-repeat: no-repeat;
  background-position: center 115%;
  
  @media only screen and (max-width: 767px) {
    background-image: url("../images/banners/banner09/bg-owl-sp.svg");
    background-position: center;
  }
  &__box{
    display: flex;
    justify-content: center;
    flex-direction: column;
    position: relative;
    .H2__title{
      --H2TitleColor: var(--mainBGColorRed);
    }
    .flowchart{
      display: flex;
      justify-content: center;
      align-items: flex-start;
      margin: 80px 0 0;
      @media only screen and (max-width: 767px) {
        flex-direction: column;
        margin: 40px 0 0;
      }
    }
    .triangle{
      margin: 100px 28px auto;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 15px 0 15px 28px;
      border-color: transparent transparent transparent var(--mainBGColorRed);
      @media only screen and (max-width: 767px) {
        margin: 35px auto;
        border-width: 30.3px 17.5px 0 17.5px;
        border-color: var(--mainBGColorRed) transparent transparent transparent;
      }
    }
    .flowchart-panel{
      padding: 40px;
      border-radius: 15px;
      background: var(--colorLightYellow);
      width: 460px;
      height: auto;
      min-height: 450px;
      @media only screen and (max-width: 767px) {
        width: 100%;
        min-height: auto;
      }
      &__top{
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: row-reverse;
        color: var(--mainBGColorRed);
        margin: 0 0 40px;
        h3{
          font-size: 32px;
          text-align: center;
          margin: auto;
          font-weight: 600;
          letter-spacing: 0.125em;
          @media only screen and (max-width: 767px) {
            font-size: 20px;
          }
        }
        .number{
          border-right: 3px var(--mainBGColorRed) solid;
          border-bottom: 3px var(--mainBGColorRed) solid;
          border-radius: 0 0 15px 0;
          padding: 10px 30px 30px 10px;
          @media only screen and (max-width: 767px) {
            border-right: 2px var(--mainBGColorRed) solid;
            border-bottom: 2px var(--mainBGColorRed) solid;
            border-radius: 0 0 15px 0;
            padding: 6px 20px 20px 6px;
          }
          img{
            height: 60px;
            @media only screen and (max-width: 767px) {
              height: 42px;
            }
          }
        }
      }
      p{
        text-align: justify;
        font-size: 18px;
        line-height: 1.75em;
        @media only screen and (max-width: 767px) {
          font-size: 14px;
        }
      }
      .courses{
        font-size: 22px;
        h4{
          margin: 1em 0 0;
          font-size: 22px;
          text-align: center;
          font-weight: 500;
          @media only screen and (max-width: 767px) {
            font-size: 18px;
          }
        }
        .course-list{
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex-direction: row;
          flex-wrap: wrap;
          margin: 0.5em 0 0;
          &__item{
            margin: 0.5em 0 0.5em;
            font-size: 18px;
            padding: 0.25em 0;
            width: 175px;
            letter-spacing: 0.100em;
            border-radius: 50px;
            text-align: center;
            background: white;
            font-weight: 500;
            white-space: nowrap;
            @media only screen and (max-width: 767px) {
              font-size: 13px;
              max-width: 120px;
              width: 100%;
              margin: 0.5em 5px 0.5em;
              @media only screen and (max-width: 678px) {
                margin: 0.5em 2em 0.5em; 
                @media only screen and (max-width: 500px) {
                  margin: 0.5em auto 0.5em;
                }
              }
            }
          }
          :nth-child(1){
            background: var(--colorPaleBlue);
          }
          :nth-child(2){
            background: var(--colorPaleLime);
          }
          :nth-child(3){
            background: var(--colorPaleOrange);
          }
          :nth-child(4){
            background: var(--colorPalePink);
          }
        }
      }
    }
  }

}