/*=============================
#hamburger-menu
=============================*/
header {
	position: relative;

	@media only screen and (max-width: 1024px) {
		/*=============================
    .btn-trigger
    =============================*/
		.btn-trigger {
			z-index: 99;
			position: relative;
			width: 25px;
			height: 22px;
			cursor: pointer;
			//transform: scale(0.5);
		}
		.btn-trigger span {
			position: absolute;
			left: 0;
			width: 100%;
			height: 2px;
			background-color: #fff;
			border-radius: 2px;
		}
		.btn-trigger,
		.btn-trigger span {
			display: inline-block;
			transition: all 0.5s;
			box-sizing: border-box;
		}
		.btn-trigger span:nth-of-type(1) {
			top: 0;
		}
		.btn-trigger span:nth-of-type(2) {
			top: 11px;
		}
		.btn-trigger span:nth-of-type(3) {
			bottom: 0;
		}

		#hamburger-menu::after {
			position: absolute;
			top: 0;
			right: 0;
			left: 0;
			bottom: 0;
			margin: auto;
			z-index: -1;
			display: block;
			//content: "";
			width: 42px;
			height: 42px;
			margin: auto 0px auto -8px;
			border-radius: 50%;
			border: 2px solid transparent;
			transition: all 0.75s;
		}
		#hamburger-menu.active span:nth-of-type(1) {
			-webkit-transform: translateY(10px) rotate(-45deg);
			transform: translateY(10px) rotate(-45deg);
		}
		#hamburger-menu.active span:nth-of-type(2) {
			left: 60%;
			opacity: 0;
			-webkit-animation: active-hamburger-menu-bar02 0.8s forwards;
			animation: active-hamburger-menu-bar02 0.8s forwards;
		}
		@-webkit-keyframes active-hamburger-menu-bar02 {
			100% {
				height: 0;
			}
		}
		@keyframes active-hamburger-menu-bar02 {
			100% {
				height: 0;
			}
		}
		#hamburger-menu.active span:nth-of-type(3) {
			-webkit-transform: translateY(-10px) rotate(45deg);
			transform: translateY(-10px) rotate(45deg);
		}
		#hamburger-menu.active::after {
			-webkit-animation: active-hamburger-menu 0.7s 0.25s forwards;
			animation: active-hamburger-menu 0.7s 0.25s forwards;
		}
		@-webkit-keyframes active-hamburger-menu {
			0% {
				border-color: transparent;
				-webkit-transform: rotate(0);
			}
			25% {
				border-color: transparent #fff transparent transparent;
			}
			50% {
				border-color: transparent #fff #fff transparent;
			}
			75% {
				border-color: transparent #fff #fff #fff;
			}
			100% {
				border-color: #fff;
				-webkit-transform: rotate(-680deg);
			}
		}
		@keyframes active-hamburger-menu {
			0% {
				border-color: transparent;
				transform: rotate(0);
			}
			25% {
				border-color: transparent #fff transparent transparent;
			}
			50% {
				border-color: transparent #fff #fff transparent;
			}
			75% {
				border-color: transparent #fff #fff #fff;
			}
			100% {
				border-color: #fff;
				transform: rotate(-680deg);
			}
		}
	}
}

.drawer-menu {
	display: block;
	margin: auto;
	@media only screen and (min-width: 1024px) {
		display: none;
	}
	a {
		color: var(--mainTextColor);
	}
	&__list {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		flex-direction: column;
		padding: 3em 2em 3em;
		&__item {
			color: var(--mainTextColor);
			margin: 1.5em 0;
			text-align: left;
			width: 100%;
		}
		.login-button {
			background: var(--mainBGColorRed);
			color: var(--mainTextColorInverted);
			width: 100%;
			height: 100%;
			border-radius: 5px;
			padding: 0.5em 1.25em;
		}
	}
}

#mask {
	transition: all 0.5s ease-in-out;
	display: block;
	position: fixed;
	width: 70vw;
	top: calc(50px);
	height: calc(100vh - 50px);
	background: var(--mainBGColorYellow);
	z-index: 2;
	cursor: pointer;
	box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.6);
	@media only screen and (min-width: 599px) and (max-width: 1024px) {
		top: 94px;
	}
}

.nav-open {
	#mask {
		left: 30vw;
	}
}
.nav-close {
	#mask {
		left: 100vw;
	}
	#masked {
		display: none;
	}
}

.nav-open {
	#masked {
		width: 30vw;
		top: calc(50px);
		height: calc(100vh - 50px);
		z-index: 0;
		position: absolute;
		top: 0px;
		right: 70vw;
		background: rgba(167, 167, 167, 0.027);
	}
}
