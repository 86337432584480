.content-staff {
	padding: 0 0 80px;
	.introduce-trainer {
		&__title {
			font-size: 18px;
			line-height: 1.75em;
			text-align: center;
			margin: 0 auto 60px;
			color: var(--mainTextColor);
			@media only screen and (max-width: 767px) {
				font-size: 14px;
				margin: 0 auto 40px;
			}
			h2 {
				font-size: 2em;
				color: var(--colorRed);
				margin: 0 auto 1em;
				font-weight: 500;
			}
			.DotsLine {
				--dotsLineWidth: 312px;
				@media only screen and (max-width: 767px) {
					--dotsLineWidth: 200px;
				}
			}
			.title-text {
				margin: 1.5em auto 0;
				line-height: 1.25em;
			}
		}
	}

	.person {
		display: flex;
		align-items: flex-start;
		justify-content: center;
		flex-direction: column;
		margin: 0 auto 60px;
		width: 100%;
		max-width: 1000px;
		&__top {
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			@media only screen and (max-width: 767px) {
				flex-direction: column;
			}
			&__image-box {
				width: 230px;
				height: 250px;
				position: relative;
				@media only screen and (max-width: 767px) {
					width: 180px;
					height: 200px;
				}
				.bubble {
					width: 230px;
					height: 230px;
					border-radius: 50%;
					position: relative;
					z-index: 1;
					@media only screen and (max-width: 767px) {
						width: 180px;
						height: 180px;
					}
				}
				img {
					display: block;
					position: absolute;
					top: 0;
					right: 20px;
					z-index: 2;
					width: 230px;
					height: 230px;
					border-radius: 50%;
					@media only screen and (max-width: 767px) {
						width: 180px;
						height: 180px;
					}
				}
				.blue-bubble {
					background: var(--colorBlue);
				}
				.red-bubble {
					background: var(--colorRed);
				}
				.yellow-bubble {
					background: var(--colorYellow);
				}
			}
			&__info {
				width: fit-content;
				max-width: 350px;
				font-size: 18px;
				display: flex;
				align-items: flex-start;
				justify-content: flex-start;
				flex-direction: column;
				margin: 0 0 0 2em;
				@media only screen and (max-width: 767px) {
					margin: 0;
					font-size: 14px;
					width: 80%;
				}
				.trainer-name {
					margin: 0 0 0.75em;
					color: var(--colorRed);
					font-size: 1.4em;
					font-weight: 500;
					text-align: left;
					width: 100%;
					@media only screen and (max-width: 767px) {
						text-align: center;
						font-size: 1.25em;
					}
				}
				.customer-name {
					margin: 0 0 0.75em;
					color: var(--colorRed);
					font-size: 1.4em;
					font-weight: 500;
					text-align: left;
					width: 100%;
					@media only screen and (max-width: 767px) {
						text-align: center;
						font-size: 1.25em;
					}
				}
				span {
					line-height: 1.5em;
				}
			}
		}
		&__bottom {
			margin: auto;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			@media only screen and (max-width: 767px) {
				align-items: center;
			}
			.comment {
				width: 100%;
				max-width: 870px;
				margin: auto;
				font-size: 16px;
				line-height: 1.4em;
				@media only screen and (max-width: 767px) {
					margin: 0;
					font-size: 14px;
					width: 80%;
				}
				h4 {
					font-size: 1.25em;
					line-height: 1.5em;
					border-bottom: 5px dotted var(--colorYellow);
					width: fit-content;
					margin: 0 0 1em;
					padding: 0.25em 1em;
					@media only screen and (max-width: 767px) {
						text-align: center;
						margin: 1em auto 1em;
					}
				}
				p {
					width: 100%;
					max-width: 600px;
				}
			}
		}
	}
}
