.effects{
  margin: 140px 0 0;
  padding: 100px 0 0;
  @media only screen and (max-width: 767px) {
    margin: 60px 0 0;
    padding: 50px 0 0;
  }
  .DotsDivider{
    position: absolute;
    bottom: -15px;
    width: 100vw;
    height: 24px;
    background-color: transparent;
    background-size: 24px 24px;
    background-image: radial-gradient(var(--colorRed) 67%, transparent 70%);
    background-repeat: repeat-x;
    @media only screen and (max-width: 767px) {
    }
  }
  .content-effects{
    position: relative;
    z-index: 5;
    .circles{
      
      .circles-box{
        margin: auto;
        width: 100%;
        max-width: 900px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        font-size: 22px;
        @media only screen and (max-width: 767px) {
          max-width: 365px;
          @media only screen and (max-width: 370px) {
            max-width: 320px;
          }
        }
        &__item{
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          align-items: center;
          --circleSize: 160px;
          font-size: 1em;
          --circleColor: var(--colorLightRed);
          position: relative;
          min-width: var(--circleSize);
          min-height: var(--circleSize);
          background: var(--circleColor);
          color: var(--mainTextColorInverted);
          padding: 0;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 30px;
          text-align: center;
          @media only screen and (max-width: 767px) {
            font-size: 10px;
            margin: 9px;
            --circleSize: calc( 160px * 0.4);
            @media only screen and (max-width: 370px) {
              --circleSize: calc( 160px * 0.35);
            }
          }
          &::before{
            --circleSize: 180px;
            position: absolute;
            z-index: -1;
            content: "";
            width: var(--circleSize);
            height: var(--circleSize);
            border-radius: 50%;
            background: #ffffff;
            @media only screen and (max-width: 767px) {
              margin: 0px;
              --circleSize: calc(145px * 0.5);
              font-size: 10px;
              @media only screen and (max-width: 370px) {
                --circleSize: calc( 145px * 0.4);
              }
            }
          }
          &::after{
            --circleSize: 200px;
            position: absolute;
            z-index: -2;
            content: "";
            width: var(--circleSize);
            height: var(--circleSize);
            background: var(--circleColor);
            border-radius: 50%;
            @media only screen and (max-width: 767px) {
              margin: 0px;
              --circleSize: calc( 160px * 0.5);
              @media only screen and (max-width: 370px) {
                --circleSize: calc( 160px * 0.4);
              }
            }
          }
        }
        .blue{
          --circleColor: var(--colorBlue);
        }
        .lime{
          --circleColor: var(--colorLime);
        }
        .orange{
          --circleColor: var(--colorOrange);
        }
        .pink{
          --circleColor: var(--colorPink);
        }
      }
      .description{
        padding: 5em 0 4em;
        margin: auto;
        font-size: 20px;
        text-align: center;
        letter-spacing: 0.015em;
        line-height: 1.5em;
        @media only screen and (max-width: 767px) {
          font-size: 14px;
          padding: 2em 2.5em 3em;
          text-align: justify;
        }
      }
    }

    .subtitle{
      margin: auto;
      padding: 2em 0 2em;
      font-size: 40px;
      color: var(--colorRed);
      font-weight: 500;
      @media only screen and (max-width: 767px) {
        font-size: 28px;
      }
      h2{
        text-align: center;
        font-size: 1.0em;
        span{
          padding: 0.25em 0 0.5em;
          display: block;
          font-size: 0.75em;
          text-transform: uppercase;
        }
      }

    }

    .messages-box{
      padding: 0 0 60px;
      @media only screen and (max-width: 767px) {
        padding: 0 0 40px;
      }
    }
    .person{
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
      margin: 0 auto 60px;
      width: 100%;
      max-width: 1000px;
      &__top{
        width: 100%;
        max-width: 800px;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        @media only screen and (max-width: 767px) {
          justify-content: center;
          @media only screen and (max-width: 500px) {
            flex-direction: column;
          }
        }
        &__image-box{
          margin: 0 4.5em 0 0;
          width: 270px;
          height: 250px;
          position: relative;
          @media only screen and (max-width: 767px) {
            width: 220px;
            height: 200px;
            margin: 0;
          }
          .bubble{
            width: 250px;
            height: 250px;
            border-radius: 50%;
            position: relative;
            z-index: 1;
            right: -20px;
            @media only screen and (max-width: 767px) {
              width: 180px;
              height: 180px;
            }
          }
          img{
            display: block;
            position: absolute;
            top: 0;
            right: 40px;
            z-index: 2;
            width: 250px;
            height: 250px;
            border-radius: 50%;
            @media only screen and (max-width: 767px) {
              width: 180px;
              height: 180px;
            }
          }
          .blue-bubble{
            background: var(--colorBlue);
          }
          .red-bubble{
            background: var(--colorRed);
          }
          .yellow-bubble{
            background: var(--colorYellow);
          }
        }
        &__info{
          width: fit-content;
          max-width: 350px;
          font-size: 18px;
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;
          flex-direction: column;
          margin: 0 0 0 2.5em;
          @media only screen and (max-width: 767px) {
            margin: 0;
            font-size: 14px;
            @media only screen and (max-width: 500px) {
              width: 80%;
            }
          }
          .belongs{
            margin: 0 0 0.75em;
            color: var(--colorRed);
            font-size: 1.45em;
            font-weight: 400;
            text-align: left;
            width: 100%;
            @media only screen and (max-width: 767px) {
              text-align: center;
              font-size: 1.25em;
            }
            .name{
              font-weight: 500;
              font-size: 1.6em;
            }
          }
          span{
            line-height: 1.5em;
          }
        }
      }
      &__bottom{
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        @media only screen and (max-width: 767px) {
          align-items: center;
        }
        .comment{
          width: 100%;
          max-width: 870px;
          margin: auto;
          font-size: 16px;
          line-height: 1.4em;
          position: relative;
          @media only screen and (max-width: 767px) {
            margin: 0;
            font-size: 14px;
            width: 80%;
          }
          h4{
            font-size: 1.25em;
            line-height: 1.5em;
            border-bottom: 5px dotted var(--colorYellow);
            width: fit-content;
            margin: 0 0 1em;
            padding: 0.25em 1em;
            @media only screen and (max-width: 767px) {
              text-align: center;
              margin: 1em auto 1em;
            }
          }
          p{
            width: 100%;
            max-width: 700px;
            letter-spacing: 0.01em;
            line-height: 1.75em;
            font-size: 1.125em;
            padding: 2em 0 0;
            @media only screen and (max-width: 767px) {
              max-width: 500px;
              font-size: 1.0em;
            }
          }
          .diagonal-line01{
            position: absolute;
            top: 0px;
            right: -1em;
            z-index: -1;
            border-radius: 10px;
            opacity: 1;
            width: 100%;
            max-width: 350px;
            @media only screen and (max-width: 767px) {
              right: 2em;
            }
          }
          .diagonal-line02{
            position: absolute;
            bottom: -1em;
            left: -1em;
            z-index: -1;
            border-radius: 10px;
            opacity: 0.65;
            width: 100%;
            max-width: 350px;
            @media only screen and (max-width: 767px) {
              left: 2em;
            }
          }
        }
      }
    }
    .evidence{
      position: relative;
      background: var(--colorYellow);
      background-image: linear-gradient(
        -45deg, 
        var(--colorSuperLightYellow) 25%,
        var(--colorLightYellow) 25%, var(--colorLightYellow) 50%,
        var(--colorSuperLightYellow) 50%, var(--colorSuperLightYellow) 75%,
        var(--colorLightYellow) 75%, var(--colorLightYellow) 100%
      );
      background-size: 380px 380px;
      position: relative;
      z-index: 5;
      box-shadow: 0 10px 25px 0 rgba(235, 235, 235, .3);
      .evidence-content{
        position: relative;
        .youtube-frame{
          --youtubePaddingWidth: 0px;
          padding: var(--youtubePaddingWidth);
          border-radius: 15px;
          //width: calc( 560px + var(--youtubePaddingWidth) + var(--youtubePaddingWidth) );
          width: fit-content;
          background: var(--colorWhite);
          margin: 0 auto 60px;
          @media only screen and (max-width: 500px) {
            --youtubePaddingWidth: 10px;
            width: initial;
            margin: 0 auto 30px;
          }
          .youtube{
            box-shadow: 0 10px 25px 0 rgba(0, 0, 0, .5);
            position: relative;
            width: 530px;
            height: 330px;
            border-radius: 15px;
            @media only screen and (max-width: 767px) {
              //padding: 56.66% 0 0;
              width: 100%;
              max-width: 600px;
              height: initial;
            }
            iframe{
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              display: block;
              border-radius: 15px;
              max-width: 600px;
              max-height: 338px;
              @media only screen and (max-width: 767px) {
                
              }
            }
          }
        }
      }
      //校長
      .owl-description{
        position: absolute;
        top: -250px;
        right: -50px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin: 40px 0 0;
        @media only screen and (max-width: 767px) {
          top: initial;
          right: initial;
          position: static;
          margin: auto;
        }
        .owl02{
          display: block;
          margin: auto;
          height: 180px;
          @media only screen and (max-width: 500px) {
            width: 120px;
          }
        }
        .fukidashi{
          white-space: nowrap;
          margin: 0 20px 20px 0;
          position:relative;
          background: var(--colorWhite);
          padding: 2em 1.5em;
          text-align: center;
          border: 1px solid var(--colorWhite);
          color: var(--mainTextColor);
          font-size: 16px;
          border-radius: 40px;
          line-height: 2em;
          letter-spacing: 0.01em;
          width: 250px;
          @media only screen and (max-width: 767px) {
            width: 220px;
            text-align: center;
            font-size: 14px;
            margin: 0 0 0px;
            border-radius: 40px;
            padding: 2em 1.5em;
          }
          &:after,&:before{
            border: solid transparent;
            content:'';
            height:0;
            width:0;
            pointer-events:none;
            position:absolute;
            left: 0;
            right: 0;
            margin: 0 auto;
            top:100%;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 20px 10px 0 10px;
            border-color: var(--colorWhite) transparent transparent transparent;
            @media only screen and (max-width: 767px) {
            }
          }
        }
      }
      .evidence-papers{
        padding: 0 0 60px;
        font-size: 24px;
        .description{
          font-size: 1.0em;
          text-align: center;
          @media only screen and (max-width: 767px) {
            font-size: 0.8em;
          }
        }
        .papers{
          padding: 60px 0 60px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          @media only screen and (max-width: 767px) {
            flex-wrap: wrap;
            padding: 30px 0px 30px;
          }
          &__item{
            //width: calc(90%);
            height: 100%;
            margin: 0 auto 0;
            padding: 1em;
            @media only screen and (max-width: 550px) {
              width: calc( 100% / 3 );
              max-height: none;
              height: auto;
              min-width: 100px;
              padding: 5px;
            }
            img{
              width: 100%;
            }
          }
        }
        .thesis-list{
          padding: 1em 0 0;
          text-align: center;
          font-size: 18px;
          @media only screen and (max-width: 767px) {
            font-size: 14px;
            text-align: justify;
            word-break: break-word;
          }
          &__item{
            font-size: 1.0em;
            line-height: 2em;
            text-decoration: underline;
            font-weight: bold;
            margin: auto;
            @media only screen and (max-width: 767px) {
              margin: 0 0 0;
            }
            a{
              font-size: 1.0em;
              color: var(--mainTextColor);
              text-align: center;
              @media only screen and (max-width: 767px) {
                text-align: justify;
              }
            }
          }
        }
      }
    }
  }
}
