.banner03 {
	//background: var(--mainBGColorYellow);
	padding: 80px 0 0px;
	position: relative;
	background-color: #eb4628;
	box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.5);
	@media only screen and (max-width: 1024px) {
		padding: 40px 0 0;
	}
	&__box {
		width: 85vw;
		margin: auto;
		h2 {
			text-align: center;
			margin: 0 0 70px;
			@media only screen and (max-width: 1024px) {
				font-size: 42px;
				margin: 0 0 40px;
			}
			@media only screen and (max-width: 599px) {
				font-size: 24px;
				margin: 0 0 40px;
			}
		}
		.video-frame {
			margin: auto;
			padding: 20px 20px;
			background: #fff;
			border-radius: 10px;
			@media only screen and (max-width: 1024px) {
				padding: 10px;
			}
			&__inner {
				margin: auto;
				width: 100%;
				height: auto;
				//max-height: 550px;
				border-radius: 10px;
				position: relative;
				overflow: hidden;
				@media only screen and (max-width: 1024px) {
					width: 100%;
					height: auto;
				}
			}
			&__wrap {
				padding: 56.25% 0 0 0;
				position: relative;
				iframe {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					border-radius: 10px;
				}
			}
		}
		.owl-check {
			display: flex;
			align-items: center;
			justify-content: center;
			.imageWrapper {
				position: relative;
				margin: auto;
				.owl-man {
					width: 700px;
					height: 350px;
					object-fit: cover;
					object-position: 0 100%;
					@media only screen and (max-width: 1024px) {
						width: 500px;
						height: 210px;
					}
					@media only screen and (max-width: 599px) {
						width: 300px;
						height: 150px;
					}
				}
				.check {
					position: absolute;
					top: 0;
					bottom: 0;
					right: 0;
					width: 150px;
					margin: auto;
					@media only screen and (max-width: 1024px) {
						width: 100px;
					}
					@media only screen and (max-width: 599px) {
						width: 70px;
					}
				}
			}
		}
	}
}
