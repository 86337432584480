header {
	* {
		color: white;
		line-height: 1.25em;
	}
	.narrow-text {
		display: inline-block;
		transform: scale(0.8, 1);
	}
	background: var(--headerBGColor);
	width: 100vw;
	font-size: var(--headerFontSize);
	position: fixed;
	top: 0;
	z-index: 999;
	.nav-box {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		height: 100px;
		padding: 22px 30px;
		#hamburger-menu {
			display: none;
		}
		@media only screen and (max-width: 1024px) {
			height: 94px;
			#hamburger-menu {
				display: block;
			}
		}
		@media only screen and (max-width: 599px) {
			height: 50px;
			#hamburger-menu {
				display: block;
			}
		}
		&__logo {
			img {
				height: 50px;
				@media only screen and (max-width: 1024px) {
					height: 50px;
				}
				@media only screen and (max-width: 599px) {
					height: 35px;
				}
			}
		}
		&__list {
			display: flex;
			align-items: center;
			justify-content: space-between;
			@media only screen and (max-width: 1024px) {
				display: none;
			}
			@media only screen and (max-width: 599px) {
				display: none;
			}
			&__item {
				margin: auto 15px;
				a {
					display: inline-block;
					width: 100%;
					height: 100%;
					text-align: center;
					font-weight: bold;
				}
				.login-button {
					background: var(--mainBGColorYellow);
					color: var(--mainBGColorRed);
					width: 100%;
					height: 100%;
					border-radius: 5px;
					padding: 0.5em 1.25em;
				}
			}
		}
	}
}
