.banner-courses {
	background-size: 20px 20px;
	background-position: 50% 50%;
	background-image: repeating-linear-gradient(
			90deg,
			var(--colorWhite),
			var(--colorWhite) 1px,
			transparent 1px,
			transparent 20px
		),
		repeating-linear-gradient(
			0deg,
			var(--colorWhite),
			var(--colorWhite) 1px,
			var(--colorAsh) 1px,
			var(--colorAsh) 20px
		);
	font-size: 22px;
	padding: 0 0 4.5em;
	&__box {
		padding-top: 2em;
		margin-top: -2em;
		@media only screen and (min-width: 1024px) {
			width: 100vw;
			max-width: 100vw;
		}
		@media only screen and (min-width: 599px) {
			width: 100vw;
			max-width: 100vw;
		}
	}
	.H2__title {
		font-size: 50px;
		padding: 2em 0 0em;
		color: var(--mainTextColor);
		@media only screen and (max-width: 1024px) {
			font-size: 42px;
		}
		@media only screen and (max-width: 599px) {
			font-size: 24px;
		}
		span {
			padding: 0 1.25em 0;
		}
		img {
			@media only screen and (max-width: 1024px) {
				height: 20px;
			}
			@media only screen and (max-width: 599px) {
				height: 20px;
			}
		}
	}

	// マイクラとかのロゴのあるボックス
	.courses {
		display: flex;
		justify-content: space-evenly;
		flex-direction: inherit;
		margin: auto;
		@media only screen and (max-width: 1024px) {
			align-items: center;
			flex-direction: column;
		}
		@media only screen and (max-width: 599px) {
			max-width: 400px;
			align-items: center;
			flex-direction: column;
		}
		&__list {
			margin: 4.75em 0 0;
			padding: 2em 1em 1.5em;
			width: 30%;
			max-width: 500px;
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;
			border: 1px solid var(--colorLime);
			font-size: 22px;
			background: var(--colorWhite);
			border-radius: 20px;
			line-height: 2em;
			position: relative;
			@media only screen and (max-width: 1024px) {
				width: 100%;
				margin: 3.5em 0 0;
				padding: 1em 1em 1.5em;
				max-width: 80vw;
			}
			@media only screen and (max-width: 599px) {
				width: 100%;
				margin: 2.5em 0 0;
				padding: 1em 1em 1.5em;
			}
			&__title {
				font-size: 30px;
				padding: 1em 0;
				text-align: center;
				span {
					font-size: 28px;
					@media only screen and (max-width: 1024px) {
						font-size: 31px;
					}
					@media only screen and (max-width: 599px) {
						font-size: 16px;
					}
				}
				@media only screen and (max-width: 1024px) {
					font-size: 31px;
				}
				@media only screen and (max-width: 599px) {
					font-size: 16px;
				}
			}
			.number-ball {
				position: absolute;
				top: -45px;
				left: 0;
				right: 0;
				margin: auto;
				display: flex;
				justify-content: center;
				align-items: center;
				flex-direction: column;
				background: var(--colorLime);
				border-radius: 50%;
				padding: 1em;
				height: 90px;
				width: 90px;
				font-size: 32px;
				color: var(--colorWhite);
				@media only screen and (max-width: 1024px) {
					top: -50px;
					font-size: 47px;
					height: 55px;
					width: 55px;
				}
				@media only screen and (max-width: 599px) {
					top: -30px;
					font-size: 24px;
					height: 55px;
					width: 55px;
				}
			}
			img {
				width: 335px;
				height: 190px;
				@media only screen and (max-width: 1024px) {
					width: 300px;
					height: auto;
				}
				@media only screen and (max-width: 599px) {
					width: 190px;
					height: auto;
				}
			}
			.description {
				letter-spacing: 0.05em;
				font-size: 21px;
				text-align: center;
				padding: 1.25em 0;
				@media only screen and (max-width: 1024px) {
					font-size: 22px;
				}
				@media only screen and (max-width: 599px) {
					font-size: 14px;
				}
			}
			.link-btn {
				font-size: 24px;
				padding: 0.75em 3em;
				color: var(--colorWhite);
				background: var(--colorLime);
				border-radius: calc(24px + 0.75em * 2);
				position: relative;
				letter-spacing: 0.125em;
				&:after {
					content: "";
					color: var(--colorWhite);
					position: absolute;
					top: 0;
					bottom: 0;
					right: 1em;
					margin: auto;
					display: block;
					width: 0;
					height: 0;
					border-style: solid;
					border-width: 13px 0 13px 22px;
					border-color: transparent transparent transparent var(--colorWhite);
				}
				@media only screen and (max-width: 1024px) {
					font-size: 31px;
					padding: 0.5em 2em;
					&:after {
						width: 0;
						height: 0;
						border-style: solid;
						border-width: 12px 0 12px 15px;
						border-color: transparent transparent transparent var(--colorWhite);
					}
				}
				@media only screen and (max-width: 599px) {
					font-size: 16px;
					padding: 0.25em 2em;
					&:after {
						width: 0;
						height: 0;
						border-style: solid;
						border-width: 7px 0 7px 10px;
						border-color: transparent transparent transparent var(--colorWhite);
					}
				}
			}
		}
		.lime {
			border: 1px solid var(--colorLime);
			.link-btn {
				background: var(--colorLime);
			}
			.number-ball {
				background: var(--colorLime);
			}
		}
		.violet {
			border: 1px solid var(--colorViolet);
			.link-btn {
				background: var(--colorViolet);
			}
			.number-ball {
				background: var(--colorViolet);
			}
		}
		.orange {
			border: 1px solid var(--colorOrange);
			.link-btn {
				background: var(--colorOrange);
			}
			.number-ball {
				background: var(--colorOrange);
			}
		}
	}
}
