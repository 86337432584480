.banner08 {
	background: var(--mainBGColorRed);
	padding: 100px 0 70px;
	position: relative;
	z-index: 8;
	box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.5);
	@media only screen and (max-width: 1024px) {
		padding: 50px 0 35px;
	}
	@media only screen and (max-width: 599px) {
		padding: 50px 0 35px;
	}
	@media only screen and (max-width: 1024px) {
		.H2__title {
			span {
				font-size: 32px;
				padding: 0 0.25em;
			}
		}
	}
	@media only screen and (max-width: 599px) {
		.H2__title {
			span {
				font-size: 20px;
				padding: 0 0.25em;
			}
		}
	}
	&__box {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		.free-lesson-title {
			margin: 0 0 0.5em;
			padding: 0.25em 0;
			font-size: 55px;
			width: 100%;
			color: var(--mainBGColorRed);
			font-weight: 600;
			letter-spacing: 0.05em;
			background: var(--mainBGColorYellow);
			text-align: center;
			border-radius: 15px;
			@media only screen and (max-width: 1024px) {
				font-size: 32px;
				border-radius: 5px;
			}
			@media only screen and (max-width: 599px) {
				font-size: 20px;
				border-radius: 5px;
			}
		}
		.aeon-giftcard {
			margin: 0 0 80px;
			width: 100%;
			max-width: 820px;
			text-align: center;
			@media only screen and (max-width: 1024px) {
				margin: 0 0 40px;
			}
			@media only screen and (max-width: 599px) {
				margin: 0 0 40px;
			}
			* {
				color: var(--mainTextColorInverted);
			}
			span {
				white-space: nowrap;
				line-height: 1.25em;
			}
			.text-header {
				font-size: 35px;
				@media only screen and (max-width: 1024px) {
					font-size: 14px;
				}
				@media only screen and (max-width: 599px) {
					font-size: 14px;
				}
			}
			.HugeText {
				font-size: 75px;
				@media only screen and (max-width: 1024px) {
					font-size: 30px;
				}
				@media only screen and (max-width: 599px) {
					font-size: 26px;
				}
				@media only screen and (max-width: 374px) {
					font-size: 24px;
				}
			}
			.TinyText {
				font-size: 22px;
				@media only screen and (max-width: 1024px) {
					font-size: 12px;
				}
				@media only screen and (max-width: 599px) {
					font-size: 12px;
				}
			}
		}

		.flowchart {
			background: white;
			border-radius: 15px;
			padding: 70px 40px 60px;
			width: 100%;
			max-width: 1000px;
			@media only screen and (max-width: 1024px) {
				padding: 50px 15px 30px;
			}
			@media only screen and (max-width: 599px) {
				padding: 50px 15px 30px;
			}
			.H2__title {
				--H2TitleColor: var(--mainBGColorRed);
				@media only screen and (max-width: 1024px) {
					align-items: center;
					letter-spacing: 0;
					font-size: 22px;
					padding: 0 0.1em 0;
				}
				@media only screen and (max-width: 599px) {
					align-items: center;
					letter-spacing: 0;
					font-size: 22px;
					padding: 0 0.1em 0;
				}
			}
			&__box {
				margin: 50px auto 0;
				display: flex;
				justify-content: center;
				align-items: flex-start;
				border-radius: 15px;
				@media only screen and (max-width: 1024px) {
					flex-direction: column;
					margin: 50px 40px 20px;
				}
				@media only screen and (max-width: 599px) {
					flex-direction: column;
					margin: 50px auto 0;
				}
				&__item {
					position: relative;
					width: calc(100% / 3);
					height: 280px;
					padding: 70px 40px 20px;
					justify-content: space-between;
					align-items: flex-start;
					flex-direction: column;
					@media only screen and (max-width: 1024px) {
						padding: 40px 40px 40px;
						height: auto;
						width: 100%;
					}
					@media only screen and (max-width: 599px) {
						padding: 40px 40px 40px;
						height: auto;
						width: 100%;
					}
					img {
						//文字級数およそ60px
						position: absolute;
						height: 60px;
						top: -30px;
						z-index: 4;
						@media only screen and (max-width: 1024px) {
							height: 90px;
							top: 30px;
						}
						@media only screen and (max-width: 599px) {
							height: 48px;
							top: 0px;
						}
					}
					.arrow__title {
						letter-spacing: 0.125em;
						font-size: 24px;
						font-weight: 600;
						margin: 0 0 1em 0;
						@media only screen and (max-width: 1024px) {
							font-size: 32px;
							margin: 1em 0 1.5em;
						}
						@media only screen and (max-width: 599px) {
							font-size: 18px;
							margin: 0 0 1em;
						}
					}
					p {
						line-height: 1.5em;
						font-size: 18px;
						letter-spacing: 0.1em;
						@media only screen and (max-width: 1024px) {
							font-size: 22px;
						}
						@media only screen and (max-width: 599px) {
							font-size: 14px;
						}
					}
				}
				.arrow-1st {
					background: var(--colorPaleLime);
					color: var(--mainTextColor);
					width: 45%;
					padding: 70px 0px 40px 35px;
					border-radius: 15px 0px 0px 15px;
					@media only screen and (max-width: 1024px) {
						width: 100%;
						padding: 40px 40px 40px;
						border-radius: 15px 15px 0 0;
						height: 350px;
						img {
							top: -20px;
						}
					}
					@media only screen and (max-width: 599px) {
						width: 100%;
						padding: 40px 20px 40px;
						border-radius: 15px 15px 0 0;
						height: auto;
						img {
							top: -20px;
						}
					}
					&:after {
						position: absolute;
						z-index: 1;
						content: "";
						display: block;
						width: 0;
						height: 0;
						border-style: solid;
						border-width: 140px 0 140px 60px;
						border-color: transparent transparent transparent
							var(--colorPaleLime);
						top: 0;
						right: -60px;
						@media only screen and (max-width: 1024px) {
							// width 100%=90vw-(15px*2)なので
							border-width: 50px calc(45vw - 60px) 0 calc(45vw - 60px);
							border-color: var(--colorPaleLime) transparent transparent
								transparent;
							top: initial;
							bottom: -50px;
							left: 0;
							right: initial;
						}
						@media only screen and (max-width: 599px) {
							// width 100%=90vw-(15px*2)なので
							border-width: 50px calc(45vw - 15px) 0 calc(45vw - 15px);
							border-color: var(--colorPaleLime) transparent transparent
								transparent;
							top: initial;
							bottom: -50px;
							left: 0;
							right: initial;
						}
					}
				}
				.arrow-2nd {
					background: var(--colorPaleYellow);
					color: var(--mainTextColor);
					width: 55%;
					padding: 70px 35px 40px 70px;
					border-radius: 0 15px 15px 0;
					@media only screen and (max-width: 1024px) {
						width: 100%;
						padding: 110px 40px 50px;
						border-radius: 0 0 15px 15px;
					}
					@media only screen and (max-width: 599px) {
						width: 100%;
						padding: 60px 20px 40px;
						border-radius: 0 0 15px 15px;
					}
				}
			}
		}
		// 体験を受けられた上で見送られてももちろん大丈夫です！…
		.bottom-text {
			font-size: 24px;
			color: var(--mainTextColorInverted);
			text-align: center;
			line-height: 2em;
			margin: 2em auto 2em;
			@media only screen and (max-width: 1024px) {
				font-size: 28px;
				text-align: justify;
			}
			@media only screen and (max-width: 599px) {
				font-size: 18px;
				text-align: justify;
			}
		}
		.line-button {
			padding: 0.5em 4em;
			border-radius: 2em 2em;
			font-size: 30px;
			letter-spacing: 0.05em;
			line-height: 1.25em;
			vertical-align: middle;
			text-align: center;
			font-weight: 500;
			color: var(--mainTextColorInverted);
			background: var(--colorLINE);
			@media only screen and (max-width: 1024px) {
				padding: 0.5em 1em;
				font-size: 34px;
				white-space: nowrap;
				border-radius: 10px;
				width: 100%;
			}
			@media only screen and (max-width: 599px) {
				font-size: 20px;
				padding: 0.5em 2em;
				white-space: nowrap;
			}
			@media only screen and (max-width: 350px) {
				font-size: 16px;
			}
		}
	}
}
