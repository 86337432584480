.banner04 {
	background: var(--mainBGColorRed);
	padding: 20px 0 120px;
	position: relative;
	box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.5);
	z-index: 6;
	.triangle {
		position: absolute;
		z-index: 5;
		bottom: -60px;
		left: 0;
		right: 0;
		margin: auto;
		content: "";
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 60px 125px 0 125px;
		border-color: var(--mainBGColorRed) transparent transparent transparent;
		filter: drop-shadow(2px 4px 2px rgba(0, 0, 0, 0.3));
		@media only screen and (max-width: 1024px) {
			bottom: -50px;
			border-width: 50px 90px 0 90px;
		}
	}
	&__box {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		.fukidashi-box {
			background: var(--colorWhite);
			border-radius: 30px;
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;
			width: 100%;
			max-width: var(--MainContentMaxWidthPC);
			margin: 300px auto 0;
			padding: 280px 100px 80px;
			@media only screen and (max-width: 1024px) {
				margin: 250px auto 0;
				padding: 160px 40px 40px;
			}
			@media only screen and (max-width: 599px) {
				margin: 180px auto 0;
				padding: 120px 15px 40px;
			}
			&__bg {
				display: flex;
				justify-content: center;
				align-items: center;
				flex-direction: column;
				width: 100%;
				position: relative;
				&__header-image {
					position: absolute;
					top: -460px;
					margin: auto;
					width: 100%;
					@media only screen and (max-width: 1024px) {
						max-width: 500px;
					}
					@media only screen and (max-width: 599px) {
						max-width: 330px;
					}
					img {
						width: 100%;
						margin: auto;
						max-width: 610px;
						display: block;
					}
					@media only screen and (max-width: 1024px) {
						top: -340px;
					}
					@media only screen and (max-width: 599px) {
						top: -240px;
					}
				}
				h2 {
					padding: 0.75em 0 0.75em;
					font-size: 42px;
					font-weight: 600;
					text-align: center;
					color: var(--colorRed);
					@media only screen and (max-width: 1024px) {
						padding: 1em 0 1em;
					}
					@media only screen and (max-width: 599px) {
						font-size: 24px;
					}
				}
			}
			p {
				font-size: 22px;
				line-height: 2em;
				@media only screen and (max-width: 1024px) {
					font-size: 22px;
				}
				@media only screen and (max-width: 599px) {
					font-size: 14px;
				}
				a {
					color: var(--colorRed);
					text-decoration: underline;
					&::before {
						content: "▶";
						width: 100%;
						display: inline;
						color: var(--colorRed);
						@media only screen and (max-width: 1024px) {
							content: "→";
							display: inline;
							color: var(--colorRed);
						}
					}
				}
				img {
					width: 100%;
					max-width: 510px;
					margin: auto;
					display: block;
				}
			}
		}
	}
}
