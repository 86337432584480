/*フォントサイズ*/
.fs8 {
  font-size: 0.8rem !important;
}
.fs9 {
  font-size: 0.9rem !important;
}
.fs10 {
  font-size: 1rem !important;
}
.fs11 {
  font-size: 1.1rem !important;
}
.fs12 {
  font-size: 1.2rem !important;
}
.fs13 {
  font-size: 1.3rem !important;
}
.fs14 {
  font-size: 1.4rem !important;
}
.fs15 {
  font-size: 1.5rem !important;
}
.fs16 {
  font-size: 1.6rem !important;
}
.fs17 {
  font-size: 1.7rem !important;
}
.fs18 {
  font-size: 1.8rem !important;
}
.fs19 {
  font-size: 1.9rem !important;
}
.fs20 {
  font-size: 2rem !important;
}
.fs21 {
  font-size: 2.1rem !important;
}
.fs22 {
  font-size: 2.2rem !important;
}
.fs23 {
  font-size: 2.3rem !important;
}
.fs24 {
  font-size: 2.4rem !important;
}
.fs25 {
  font-size: 2.5rem !important;
}
.fs26 {
  font-size: 2.6rem !important;
}
.fs27 {
  font-size: 2.7rem !important;
}
.fs28 {
  font-size: 2.8rem !important;
}
.fs29 {
  font-size: 2.9rem !important;
}
.fs30 {
  font-size: 3rem !important;
}
.fs31 {
  font-size: 3.1rem !important;
}
.fs32 {
  font-size: 3.2rem !important;
}
.fs33 {
  font-size: 3.3rem !important;
}
.fs34 {
  font-size: 3.4rem !important;
}
.fs35 {
  font-size: 3.5rem !important;
}
.fs36 {
  font-size: 3.6rem !important;
}
.fs37 {
  font-size: 3.7rem !important;
}
.fs38 {
  font-size: 3.8rem !important;
}
.fs39 {
  font-size: 3.9rem !important;
}
.fs40 {
  font-size: 4rem !important;
}


.bold {
  font-weight: bold !important;
}

/*フォントスタイル定義*/
.underline {
  text-decoration: underline!important;
}
.textdc_none {
  text-decoration: none !important;
} /*アンダーライン外す*/

/*マージン関連定義*/
@for $i from 0 through 20 {
  .m#{$i \* \5} {
    margin: #{$i * 5}px !important;
  }
  .mt#{$i * 5} {
    margin-top: #{$i * 5}px !important;
  }
  .mb#{$i * 5} {
    margin-bottom: #{$i * 5}px !important;
  }
  .ml#{$i * 5} {
    margin-left: #{$i * 5}px !important;
  }
  .mr#{$i * 5} {
    margin-right: #{$i * 5}px !important;
  }
  .p#{$i * 5} {
    padding: #{$i * 5}px !important;
  }
  .pt#{$i * 5} {
    padding-top: #{$i * 5}px !important;
  }
  .pb#{$i * 5} {
    padding-bottom: #{$i * 5}px !important;
  }
  .pl#{$i * 5} {
    padding-left: #{$i * 5}px !important;
  }
  .pr#{$i * 5} {
    padding-right: #{$i * 5}px !important;
  }
}
@for $i from 0 through 15 {
  .m-#{$i * 1} {
    margin: #{$i * 1%} !important;
  }
  .m-t#{$i * 1} {
    margin-top: #{$i * 1%} !important;
  }
  .m-b#{$i * 1} {
    margin-bottom: #{$i * 1%} !important;
  }
  .m-l#{$i * 1} {
    margin-left: #{$i * 1%} !important;
  }
  .m-r#{$i * 1} {
    margin-right: #{$i * 1%} !important;
  }
  .p-#{$i * 1} {
    padding: #{$i * 1%} !important;
  }
  .p-t#{$i * 1} {
    padding-top: #{$i * 1%} !important;
  }
  .p-b#{$i * 1} {
    padding-bottom: #{$i * 1%} !important;
  }
  .p-l#{$i * 1} {
    padding-left: #{$i * 1%} !important;
  }
  .p-r#{$i * 1} {
    padding-right: #{$i * 1%} !important;
  }
}

.mauto {
  margin: 0 auto;
}
.mlauto {
  margin: 0 auto 0 0 !important;
}
.mrauto {
  margin: 0 0 0 auto !important;
}

/*width関連定義*/
.mw50 {
  max-width: 50%;
}
.mw95 {
  max-width: 95%;
}
.mw94 {
  max-width: 94%;
}
.mw90 {
  max-width: 90%;
}
.mw80 {
  max-width: 80%;
}
.mw100 {
  max-width: 100% !important;
}

@for $i from 1 through 100 {
  .w#{$i} {
    width: #{$i * 1%} !important;
  }
}
.heightauto {
  height: auto;
}

/*text-align関連定義*/
.ta_r {
  text-align: right !important;
}
.ta_l {
  text-align: left !important;
}
.ta_c {
  text-align: center !important;
}

.inline-block {
  display: inline-block !important;
  vertical-align: middle;
}
.block {
  display: block !important;
}
.none {
  display: none;
}
.radius {
  border-radius: 5px !important;
}
/*line-height関連定義*/
.lh13 {
  line-height: 1.3em;
}
.lh14 {
  line-height: 1.4em;
}
.lh15 {
  line-height: 1.5em;
}
.lh16 {
  line-height: 1.6em;
}
.lh17 {
  line-height: 1.7em;
}
.lh18 {
  line-height: 1.8em;
}
.lh19 {
  line-height: 1.9em;
}
.lh20 {
  line-height: 2em;
}
.lh30 {
  line-height: 3em;
}
.letterSpacing05 {
  letter-spacing: 0.5rem;
}
.letterSpacing10 {
  letter-spacing: 1rem;
}
.letterSpacing20 {
  letter-spacing: 2rem;
}
.hidden {
  width: 100%;
  overflow: hidden !important;
  position: fixed;
  left: 50%;
  transform: translate(-50%, 0);
}
.opacity5 {
  opacity: 0.4 !important;
}



// ユーティリティクラス
.underline{
  text-decoration: underline;
}
.red{
  color: var(--mainBGColorRed);
}
.bold{
  font-weight: bold;
}
