.explainer-video {
	padding: 150px 0 150px;
	position: relative;
	box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.5);
	@media only screen and (min-width: 599px) {
		height: 800px;
	}
	@media only screen and (max-width: 1024px) {
		height: 550px;
		padding: 90px 0 90px;
	}
	@media only screen and (max-width: 599px) {
		height: 370px;
		padding: 50px 0;
	}
	&__box {
		width: 85vw;
		margin: auto;
		@media only screen and (max-width: 1024px) {
			max-width: 570px;
		}
		@media only screen and (min-width: 599px) {
			height: 100%;
			display: flex;
		}
		h2 {
			text-align: center;
			margin: 0 0 0;
			font-family: fot-tsukuardgothic-std, sans-serif;
			font-weight: 700;
			font-size: 42px;
			@media only screen and (min-width: 599px) {
				position: absolute;
				right: 90px;
				top: 110px;
				letter-spacing: 0.1em;
			}
			@media only screen and (max-width: 1024px) {
				position: absolute;
				font-size: 28px;
				right: 0;
				top: 80px;
			}
			@media only screen and (max-width: 599px) {
				position: static;
				margin: 0 0 20px;
				font-size: 16px;
			}
		}
		.PC-wrap {
			@media only screen and (min-width: 599px) {
				width: 1000px;
				position: relative;
				display: flex;
				justify-content: space-evenly;
				align-items: end;
			}
		}
		.owl-man {
			text-align: center;
			@media only screen and (max-width: 599px) {
				width: 300px;
				margin: auto;
				position: relative;
				margin-bottom: 15px;
			}
			&-img {
				width: 240px;
				@media only screen and (max-width: 1024px) {
					width: 160px;
				}
				@media only screen and (max-width: 599px) {
					width: 100px;
				}
			}
			.speech_bubble {
				@media only screen and (max-width: 599px) {
					position: absolute;
					top: -30px;
					right: 50px;
				}
				&-img {
					width: 300px;
					@media only screen and (min-width: 599px) {
						transform: scale(-1, 1);
						position: absolute;
						top: 185px;
						left: 210px;
					}
					@media only screen and (max-width: 1024px) {
						width: 200px;
						transform: scale(-1, 1);
						position: absolute;
						top: 140px;
						left: 100px;
					}
					@media only screen and (max-width: 599px) {
						transform: scale(1, 1);
						position: static;
						width: 140px;
					}
				}
			}
			.logo-img {
				width: 400px;
				@media only screen and (min-width: 599px) {
					position: absolute;
					top: 0px;
					left: 120px;
				}
				@media only screen and (max-width: 1024px) {
					left: 50px;
					width: 280px;
				}
				@media only screen and (max-width: 599px) {
					width: 180px;
				}
			}
			img:nth-child(2) {
				margin: 0 0 10px 0;
			}
		}
		.youtube {
			@media only screen and (max-width: 599px) {
				height: 80px;
				position: relative;
				text-align: center;
				width: 300px;
				margin: 0 auto;
			}
			&-logo {
				@media only screen and (min-width: 599px) {
					margin: 0 0 50px 0;
				}
				@media only screen and (max-width: 599px) {
					position: absolute;
					bottom: 0;
					left: 0;
					right: 0;
					margin: auto;
				}
				&-img {
					width: 390px;
					@media only screen and (max-width: 1024px) {
						width: 270px;
					}
					@media only screen and (max-width: 599px) {
						width: 183px;
					}
				}
			}
			.check {
				@media only screen and (min-width: 599px) {
					position: absolute;
					right: 100px;
					top: 260px;
				}
				@media only screen and (max-width: 1024px) {
					position: absolute;
					right: 0px;
					top: 210px;
				}
				@media only screen and (max-width: 599px) {
					right: 35px;
					top: 0;
				}
				&-img {
					width: 132px;
					@media only screen and (max-width: 1024px) {
						width: 90px;
					}
					@media only screen and (max-width: 599px) {
						width: 60px;
					}
				}
			}
		}
	}
}
