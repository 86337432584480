.banner07{
  background: white;
  padding: 80px 0 70px;
  position: relative;
  z-index: 7;
  box-shadow: 0 10px 25px 0 rgba(0, 0, 0, .5);
  height: 1920px;
  @media only screen and (max-width: 767px) {
    height: 1560px;
  }
  &__box{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    h2{
      --H2TitleColor: var(--mainBGColorRed);
    }

    .bubbles{
      display: block;
      position: relative;


      .Bubble{
        font-size: 18px;
        position: absolute;
        margin: 0;
        padding: 2em;
        display: flex;
        justify-content: center;
        align-items: center;
        p{
          text-align: justify;
        }
        @media only screen and (max-width: 767px) {
          font-size: 14px;
        }
      }

      .Notice{
        color: var(--mainBGColorRed);
        text-decoration: underline;
      }

      .Red{
        background: var(--mainBGColorRed);
        color: var(--mainTextColorInverted);
      }

      .Yellow{
        background: var(--mainBGColorYellow);
      }

      .BubblePhoto{
        width: 396px;
        border-radius: 50%;
        @media only screen and (max-width: 767px) {
          padding: 0;
          position: absolute;
          width: 125px;
        }
      }

      .LargeBubble{
        width: 308px;
        height: 308px;
        border-radius: 50%;
        @media only screen and (max-width: 767px) {
          width: 230px;
          height: 230px;
          border-radius: 50%;
        }
      }
      .MiddleBubble{
        width: 178px;
        height: 178px;
        border-radius: 50%;
        @media only screen and (max-width: 767px) {
          width: 85px;
          height: 85px;
        }
      }
      .TinyBubble{
        width: 58px;
        height: 58px;
        border-radius: 50%;
        @media only screen and (max-width: 767px) {
          width: 25px;
          height: 25px;
        }
      }

      .bubble-child01{
        top: 20px;
        right: 50px;
        @media only screen and (max-width: 767px) {
          top: 497px;
          right: 86px;
        }
      }
      .bubble-child02{
        top: 735px;
        left: -30px;
        @media only screen and (max-width: 767px) {
          top: 1168px;
          left: 77px;
        }
      }

      .bubble01{
        top: 130px;
        left: 70px;
        @media only screen and (max-width: 767px) {
          top: 30px;
          left: -70px;
        }
      }
      .bubble02{
        top: 540px;
        right: 234px;
        @media only screen and (max-width: 767px) {
          top: 260px;
          right: -70px;
        }
      }
      .bubble03{
        top: 470px;
        left: 270px;
        @media only screen and (max-width: 767px) {
          top: 490px;
          left: -65px;
        }
      }
      .bubble04{
        top: 1080px;
        left: 270px;
        @media only screen and (max-width: 767px) {
          top: 1180px;
          right: -50px;
          left: initial;
        }
      }
      .bubble05{
        top: 1120px;
        right: 270px;
        @media only screen and (max-width: 767px) {
          top: 930px;
          left: -50px;
          right: initial;
        }
      }
      .bubble06{
        top: 1360px;
        left: -270px;
        @media only screen and (max-width: 767px) {
          top: 700px;
          right: -50px;
          left: initial;
        }
      }

      // ここからちいさなバブル
      .bubble07{
        top: -50px;
        left: 300px;
        @media only screen and (max-width: 767px) {
          top: 800px;
          left: 121px;
        }
      }
      .bubble08{
        top: 840px;
        right: 120px;
        @media only screen and (max-width: 767px) {
          top: 1040px;
          right: 120px;
        }
      }
      .bubble09{
        top: 500px;
        left: -101px;
        @media only screen and (max-width: 767px) {
          top: 200px;
          left: -148px;
        }
      }
      .bubble10{
        top: 1180px;
        right: 62px;
        @media only screen and (max-width: 767px) {
          top: 1300px;
          right: -123px;
        }
      }
      .bubble11{
        top: 580px;
        left: 24px;
        @media only screen and (max-width: 767px) {
          top: 300px;
          left: 90px;
        }
      }
      .bubble12{
        top: 1460px;
        left: 230px;
        @media only screen and (max-width: 767px) {
          top: 30px;
          right: 90px;
          left: initial;
        }
      }
    }
  }
  
}