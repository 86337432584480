@use "../modules/reset";
@use "../modules/basic_class";

@font-face {
	font-family: "Oswald";
	font-style: normal;
	font-weight: 400;
	src: local("Oswald"), url("../fonts/Oswald-Regular.ttf") format("truetype");
}

@font-face {
	font-family: "Noto Sans JP";
	font-style: normal;
	font-weight: 400;
	src: url("../fonts/Noto-Sans-CJK-JP-Regular-subset.woff2");
}

@font-face {
	font-family: "Noto Sans JP";
	font-style: normal;
	font-weight: 500;
	src: url("../fonts/Noto-Sans-CJK-JP-Medium-subset.woff2");
}
@font-face {
	font-family: "NotoSansJP-Medium";
	font-style: normal;
	font-weight: 500;
	src: url("../fonts/Noto-Sans-CJK-JP-Medium-subset.woff2");
}

@font-face {
	font-family: "Noto Sans JP";
	font-style: bold;
	font-weight: 600;
	src: url("../fonts/Noto-Sans-CJK-JP-Bold-subset.woff2");
}

@font-face {
	font-family: "NotoSansJP-Bold";
	font-style: bold;
	font-weight: 700;
	src: url("../fonts/Noto-Sans-CJK-JP-Bold-subset.woff2");
}

@font-face {
	font-family: "Noto Sans JP";
	font-style: bold;
	font-weight: 700;
	src: url("../fonts/Noto-Sans-CJK-JP-Black-subset.woff2");
}

:root {
	--mainBGColorYellow: rgb(255, 245, 30);
	--mainBGColorLightYellow: rgba(255, 245, 50, 0.816);
	--mainBGColorRed: rgb(235, 70, 40);
	--mainBGColorLightRed: rgba(233, 80, 53, 0.843);
	--headerBGColor: rgb(235, 70, 40);
	--mainBGColorGreen: rgb(52, 157, 75);
	--mainBGColorLightGreen: rgba(52, 157, 75, 0.9);
	--mainBGGridPalePink: #ffe7e2;
	--headerFontSize: 16px;
	--headerBreakpoint: 935px;
	--mainTextColor: #333;
	--colorGray: rgb(89, 87, 87);
	--colorAsh: #e8e8e8;
	--colorBlack: #3e3a39;
	--mainTextColorInverted: rgb(255, 255, 255);
	--MainContentMaxWidthPC: 1000px;
	--MaxWidthPC: 1440px;
	color: rgb(51, 51, 51);
	--mainFontFamily: "NotoSansCJKjp" sans-serif;
	font-family: "Noto Sans JP", "Noto Sans CJK JP", "Noto Sans", sans-serif;
	--colorWhite: rgb(255, 255, 255);
	--colorBlue: rgb(50, 185, 230);
	--colorLime: rgb(120, 210, 75);
	--colorOrange: #e98d00;
	--colorPink: rgb(255, 150, 165);
	--colorYellow: rgb(255, 245, 30);
	--colorAmber: #ffd855;
	--colorRed: rgb(235, 70, 40);
	--colorGreen: rgb(13, 165, 104);
	--colorViolet: #d279dc;

	--colorLINE: #5ab900;
	--colorLightBlue: rgba(89, 193, 228, 0.88);
	--colorLightLime: rgba(161, 226, 128, 0.88);
	--colorLightOrange: rgba(252, 188, 40, 0.88);
	--colorLightPink: rgba(255, 170, 183, 0.88);
	--colorLightYellow: rgba(253, 244, 73, 0.88);
	--colorLightRed: rgba(255, 94, 65, 0.88);

	--colorPaleLime: rgb(180, 230, 155);
	--colorPaleYellow: rgba(253, 244, 73, 1);
	--colorPaleRed: rgba(255, 94, 65, 1);
	--colorPaleBlue: rgb(160, 215, 240);
	--colorPaleOrange: rgb(255, 215, 67);
	--colorPalePink: rgb(255, 195, 205);

	--colorSuperLightYellow: rgb(255, 251, 168);

	--swiper-theme-color: #ffffff !important;
}

a {
	opacity: 1;
	transition: opacity 0.3s ease-in-out;
	&:hover {
		opacity: 0.8;
		transition: opacity 0.3s ease-in-out;
	}
}
body {
	font-family: "Noto Sans JP", "Noto Sans CJK JP", "Noto Sans", sans-serif;
	overflow-x: hidden;
	font-feature-settings: "palt";
	letter-spacing: 0.01em;
}
main {
	display: block;
}
.swiper-container {
	width: 100%;
	height: 100%;
	position: relative;
	overflow: hidden;
	max-width: 1440px;
	margin: auto;
}
.swiper-container.visible {
	overflow: visible;
}
.sample-swiper-cards {
	padding: 0 50px;
}
.swiper-button-next:after,
.swiper-rtl .swiper-button-prev:after {
	position: absolute;
	right: 10px;
}

.top {
	margin: 100px 0 0;
	@media only screen and (max-width: 1024px) {
		margin: 94px 0 0;
	}
	@media only screen and (max-width: 599px) {
		margin: 50px 0 0;
	}
}

.banners {
	width: 100vw;
}
.BannerBox {
	width: 90vw;
	max-width: var(--MainContentMaxWidthPC);
	margin: auto;
	@media only screen and (max-width: 1000px) {
		width: 90vw;
	}
}

//PCで表示
.PC {
	@media only screen and (min-width: 1025px) {
		display: initial !important;
	}
	@media only screen and (max-width: 1024px) {
		display: none;
	}
}
// TABで表示
.TAB {
	@media only screen and (min-width: 599px) and (max-width: 1024px) {
		display: initial !important;
	}
	@media screen and (min-width: 1024px) {
		display: none;
	}
	@media only screen and (max-width: 599px) {
		display: none;
	}
}
//SPで表示
.SP {
	@media only screen and (min-width: 600px) {
		display: none;
	}
	@media only screen and (max-width: 599px) {
		display: initial;
	}
}
.ColorBlue {
	background-color: var(--colorBlue);
	color: white;
	* {
		color: white;
	}
}
.ColorLime {
	background-color: var(--colorLime);
	color: white;
	* {
		color: white;
	}
}
.ColorOrange {
	background-color: var(--colorOrange);
	color: white;
	* {
		color: white;
	}
}
.ColorPink {
	background-color: var(--colorPink);
	color: white;
	* {
		color: white;
	}
}

.ColorLightBlue {
	background-color: var(--colorLightBlue);
}
.ColorLightLime {
	background-color: var(--colorLightLime);
}
.ColorLightOrange {
	background-color: var(--colorLightOrange);
}
.ColorLightPink {
	background-color: var(--colorLightPink);
}

.H2__title {
	--H2TitleColor: white;
	display: flex;
	justify-content: center;
	align-items: baseline;
	color: var(--H2TitleColor);
	width: 100%;
	font-weight: 500;
	white-space: nowrap;
	margin: auto;
	padding: 0;
	font-size: 50px;
	@media only screen and (max-width: 767px) {
		font-size: 24px;
	}
	div {
		background: var(--H2TitleColor);
		width: 5px;
		height: 35px;
		position: relative;
		margin: 0 12px 0;
		@media only screen and (max-width: 767px) {
			width: 3px;
			height: 18px;
			bottom: -2px;
			margin: 0 5px 0;
		}
		&:before {
			display: block;
			position: absolute;
			top: -18px;
			content: "";
			width: 5px;
			height: 8px;
			background: var(--H2TitleColor);
			@media only screen and (max-width: 767px) {
				width: 3px;
				height: 5px;
				top: -10px;
			}
		}
		&:nth-child(1) {
			transform: rotate(-60deg);
		}
		&:nth-child(2) {
			transform: rotate(-30deg);
		}
		&:nth-child(4) {
			transform: rotate(30deg);
		}
		&:nth-child(5) {
			transform: rotate(60deg);
		}
	}
	span {
		font-size: 1em;
		padding: 0 0.5em 0;
		font-weight: 600;
		line-height: 1.25em;
		text-align: center;
		@media only screen and (max-width: 767px) {
			font-size: 1em;
			padding: 0 1em 0;
		}
	}
}

.PageMainBox {
	width: 70vw;
	max-width: var(--MaxWidthPC);
	margin: auto;
	height: 100vh;
	overflow: scroll;
	@media only screen and (max-width: 935px) {
		padding: 20px 0 0;
		width: 90vw;
	}
}

.PageHeader {
	position: relative;
	padding: 140px 0 100px;
	@media only screen and (max-width: 767px) {
		padding: 60px 0 50px;
	}
	//フクロウ
	.owl-description {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		margin: 40px 0 0;
		@media only screen and (max-width: 767px) {
			margin: 0 0 0;
		}
		.owl {
			display: block;
			margin: 0 0 0 15px;
			height: 280px;
			@media only screen and (max-width: 500px) {
				width: 180px;
				margin: 0;
			}
		}
		.owl02 {
			display: block;
			margin: 0 40px 0 0;
			width: 400px;
			padding: 0 10px;
			border-bottom: 10px solid var(--colorRed);
			@media only screen and (max-width: 500px) {
				width: 350px;
				margin: 0;
			}
			@media only screen and (max-width: 500px) {
				width: 200px;
			}
		}

		.fukidashi {
			margin: 0 0 40px 40px;
			position: relative;
			width: auto;
			background: var(--colorYellow);
			padding: 3em 2.5em;
			text-align: center;
			border: 1px solid var(--colorYellow);
			color: var(--mainTextColor);
			font-size: 18px;
			border-radius: 40px;
			line-height: 2em;
			letter-spacing: 0.01em;
			@media only screen and (max-width: 767px) {
				text-align: center;
				width: 80%;
				max-width: 500px;
				font-size: 14px;
				margin: 0 0 0;
				border-radius: 40px;
				padding: 2em 1.5em;
			}
			&:after,
			&:before {
				border: solid transparent;
				content: "";
				height: 0;
				width: 0;
				pointer-events: none;
				position: absolute;
				left: 0;
				right: 0;
				margin: 0 auto;
				top: 100%;
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 20px 10px 0 10px;
				border-color: var(--colorYellow) transparent transparent transparent;
				@media only screen and (max-width: 767px) {
				}
			}
		}
	}
}

//ページタイトル
.TitleHeader {
	padding: 40px 0 50px;
	font-weight: 500;
	white-space: nowrap;
	font-size: 50px;
	line-height: 100%;
	&__MainTitle {
		font-size: 50px;
		text-transform: uppercase;
		--PageTitleColor: var(--colorRed);
		display: flex;
		justify-content: center;
		align-items: baseline;
		color: var(--PageTitleColor);
		width: 100%;
		font-weight: 700;
		white-space: nowrap;
		margin: 0;
		padding: 60px 0 0 0;
		@media only screen and (max-width: 767px) {
			padding: 40px 0 0 0;
			font-size: 27px;
		}
		div {
			background: var(--PageTitleColor);
			width: 5px;
			height: 35px;
			position: relative;
			margin: 0 12px 0;
			@media only screen and (max-width: 767px) {
				width: 3px;
				height: 18px;
				bottom: -2px;
				margin: 0 5px 0;
			}
			&:before {
				display: block;
				position: absolute;
				top: -18px;
				content: "";
				width: 5px;
				height: 8px;
				background: var(--PageTitleColor);
				@media only screen and (max-width: 767px) {
					width: 3px;
					height: 5px;
					top: -10px;
				}
			}
			&:nth-child(1) {
				transform: rotate(-60deg);
			}
			&:nth-child(2) {
				transform: rotate(-30deg);
			}
			&:nth-child(4) {
				transform: rotate(30deg);
			}
			&:nth-child(5) {
				transform: rotate(60deg);
			}
		}
		span {
			text-transform: uppercase;
			font-size: 50px;
			padding: 0 0.5em 0;
			letter-spacing: 0.1em;
			font-weight: 600;
			line-height: 1.25em;
			text-align: center;
			@media only screen and (max-width: 767px) {
				font-size: 26px;
				padding: 0 0.75em 0;
			}
		}
	}
	&__subtitle {
		font-size: 0.6em;
		color: var(--colorRed);
		text-align: center;
		line-height: 3em;
	}
}

//             //
//  固定ページ   //
//             //
.BackgroundImage {
	z-index: 1;
	background-position: top;
	background-repeat: repeat;
	background-size: 100% auto;
	//margin-top: 20px;
	@media only screen and (max-width: 767px) {
		background-size: 100% auto;
	}
	@media only screen and (min-width: 1800px) {
		background-size: auto;
	}
}

.DotsLine {
	--dotsLineWidth: 312px;
	width: var(--dotsLineWidth);
	height: 1px;
	//border-bottom: 10px dotted var(--colorYellow);
	margin: 1em auto 0;
	height: 24px;
	background-color: transparent;
	background-size: 24px 24px;
	background-image: radial-gradient(var(--colorYellow) 60%, transparent 60%);
	background-repeat: repeat-x;
	@media only screen and (max-width: 767px) {
		--dotsLineWidth: 235px;
		width: var(--dotsLineWidth);
		height: 18px;
		background-size: 18px 18px;
		background-image: radial-gradient(var(--colorYellow) 60%, transparent 60%);
	}
}

.BorderLine {
	display: flex;
	justify-content: center;
}

//スタッフ紹介
.staff {
	.PageHeader {
		padding: 140px 0 60px;
		@media only screen and (max-width: 767px) {
			padding: 60px 0 30px;
		}
	}
	.BackgroundImage {
		background-image: url("../images/frame/staff-bg-bubble.png");
		@media only screen and (min-width: 1800px) {
			background-image: url("../images/frame/staff-bg-frame.png");
			background-size: auto;
		}
		@media only screen and (max-width: 500px) {
			background-image: url("../images/frame/staff-bg-frame.png");
			background-size: 130% auto;
		}
	}
}
//FAQ
.faq {
	.BackgroundImage {
		background-size: 20px px;
		background-position: 50% 50%;
		background-image: repeating-linear-gradient(
				90deg,
				var(--mainBGGridPalePink),
				var(--mainBGGridPalePink) 1px,
				transparent 1px,
				transparent 20px
			),
			repeating-linear-gradient(
				0deg,
				var(--mainBGGridPalePink),
				var(--mainBGGridPalePink) 1px,
				#fff 1px,
				#fff 20px
			);
	}
}

//news
.news {
	.BackgroundImage {
		background-size: 20px;
		background-position: 50% 50%;
		background-image: repeating-linear-gradient(
				90deg,
				var(--mainBGGridPalePink),
				var(--mainBGGridPalePink) 1px,
				transparent 1px,
				transparent 20px
			),
			repeating-linear-gradient(
				0deg,
				var(--mainBGGridPalePink),
				var(--mainBGGridPalePink) 1px,
				#fff 1px,
				#fff 20px
			);
	}

	.PageHeader {
		background: var(--colorYellow);
		.fukidashi {
			color: var(--mainTextColor);
			background: #fff;
			&:after,
			&:before {
				border-color: #fff transparent transparent transparent;
			}
		}
	}
}

//single-news
.single-news {
	.DotsLine {
		background-image: radial-gradient(var(--colorRed) 60%, transparent 60%);
	}
}

//effects
.effects {
	.BackgroundImage {
		@media only screen and (max-width: 1799px) {
			background-image: url("../images/frame/effects-bg-bubble.png");
		}
		@media only screen and (min-width: 1800px) {
			background-image: url("../images/frame/effects-bg-frame.png");
			background-size: 100% auto;
		}
		// @media only screen and (max-width: 500px) {
		//   background-image: url("../images/frame/effects-bg-frame_sp.png");
		// }
		@media only screen and (max-width: 500px) {
			background-size: 120% auto;
		}
	}
	.DotsLine {
		background-image: radial-gradient(var(--colorRed) 60%, transparent 60%);
	}
	.PageMainBox {
		@media only screen and (max-width: 935px) {
			padding: 0 0 0;
		}
	}
}

.wide-horizontal-line {
	display: block;
	margin: 0;
	padding: 0;
	border-top: 80px solid var(--colorRed);
	@media only screen and (max-width: 935px) {
		border-top: 22px solid var(--colorRed);
	}
}
.wide-horizontal-line-margin {
	margin: 0 0 3em 0;
}
.narrow-horizontal-line {
	display: block;
	margin: 0;
	padding: 0;
	border-top: 8px solid var(--colorWhite);
}
.new_line {
	display: inline-block;
}
