html {
	scroll-behavior: smooth;
}

.notification-bar {
	background: #fff;
	padding: 8px 0;
	font-size: 16px;
	color: #349d4b;
	text-align: center;
	overflow: hidden;
	display: flex;
	gap: 100px;
	width: 100%;
	& div {
		display: inline-block;
		padding-left: 100%;
		white-space: nowrap;
		animation: scroll 30s linear infinite;
		display: flex;
		gap: 100px;
		& p {
			font-weight: bold;
		}
		& a {
			color: #349d4b;
			text-decoration: underline;
		}
		& > * {
			display: inline-block;
		}
	}
	@keyframes scroll {
		0% {
			transform: translateX(0);
		}
		100% {
			transform: translateX(-100%);
		}
	}
}
