.news{
	font-family: fot-tsukuardgothic-std, sans-serif;
	padding: 100px 0 0;
  .PageHeader{
    position: relative;
    z-index: 6;
    margin: 0;
  }
  @media only screen and (max-width: 768px) {
    padding: 50px 0 0;
  }
  .content-news{
    // text-transform: uppercase;
    position: relative;
    z-index: 5;
    padding: 0 0 200px;
    @media only screen and (max-width: 768px) {
      padding: 0 0 80px;
    }
  }

  &-title{
    font-size: 20px;
    padding: 1.5em 0 1.5em;
    text-transform: uppercase;
    @media only screen and (max-width: 768px) {
      font-size: 14px;
      text-align: center;
    }
    h2{
      text-transform: uppercase;
      padding: 0 0 0;
      margin: auto;
      text-align: center;
      max-width: 312px;
      font-size: 2em;
      font-weight: 500;
      color: var(--colorRed);
      width: 100%;
      @media only screen and (max-width: 768px) {
        max-width: 234px;
      }
    }
  }

  &-box{
    position: relative;
    max-width: 550px;
      margin: 50px auto 90px;
      width: 100%;
    padding: 0;
    color: var(--colorRed);
    color: var(--mainTextColor);
    @media only screen and (max-width: 500px) {
      width: 80%;
      margin: 50px auto 30px;
    }
    @media only screen and (min-width: 768px) {
      max-width: 600px;
      margin: 50px auto 50px;
    }
    @media only screen and (min-width: 1000px) {
      max-width: 800px;
      margin: 50px auto 90px;
  }
    @media only screen and (min-width: 1300px) {
      max-width: 1000px;
    }
    &__item{
      color: var(--colorRed);
      width: 100%;
      padding: 0em 1em 1em;
      font-size: 24px;
      line-height: 1.5em;
      margin: auto;
      border-bottom: 1px solid var(--colorRed);
      &:not(:first-child){
        padding-top: 1em;
      }
      @media only screen and (max-width: 768px) {
        font-size: 16px;
      }
      a{
        text-decoration: none;
      }
      &__text{
        display: flex;
        align-items: center;
        font-size: 20px;
        line-height: 2em;
        color: var(--mainTextColor);
        align-items: flex-start;
        flex-direction: column;
        @media only screen and (max-width: 768px) {
          font-size: 16px;
          justify-content: center;
        }
        .top-text{
          font-size: 1em;
          display: flex;
          align-items: center;
          font-weight: 500;
          line-height: 1.5em;
          @media only screen and (max-width: 768px) {
            padding: 0 0 1em;
          }
          .date{
            font-size: 32px;
            margin: 0 1.5em 0 0;
            letter-spacing: 0.02em;
            color: var(--colorRed);
          @media only screen and (max-width: 768px) {
            font-size: 1em;
          }
          }
          .label{
            border-radius: 15px;
            margin: 0 3em 0 0;
            font-size: 0.8em;
            padding: 0.25em 1.5em;
            background: var(--colorRed);
            color: var(--mainTextColorInverted);
            @media only screen and (max-width: 768px) {
              margin: 0;
            }
          }
        }
        .bottom-text{
          display: flex;
          justify-content: space-between;
          padding: 1em 0 0 6em;
          font-size: 32px;
          letter-spacing: 0.02em;
          vertical-align: middle;
          color: var(--colorRed);
          word-break: break-all;
          @media only screen and (max-width: 768px) {
            padding:0 ;
            font-size: 1em;
          }
          &__wrap{
            width: 100%;
          }
        }
      }
    }
    &_pageNation{
      display: flex;
      justify-content: center;
      gap:30px;
      font-size: 26px;
      color: #000;
      margin-bottom: 100px;
      font-weight: bold;
      @media only screen and (max-width: 1024px) {
      font-size: 18px;
      }
      @media screen and (max-width: 599px) {
      font-size: 16px;
      }
      a{
        color: #000;
      }
    .current{
      text-decoration: underline;
      text-decoration-thickness:1px;
      text-underline-offset: 4px;
      }
      .prev{
        width: 40px;
        height: 40px;
        transition: all 0.3s;
        border-radius: 100%;
        border: solid #EB4628 1px;
        position: relative;
        transform: rotate(180deg);
        &:before{
          position: absolute;
          margin: auto;
          top: -8px;
          bottom: 0;
          left: 0;
          right: -5px;
          content:"";
          width: 15px;
          height: 1px;
          display: block;
          transform: rotate(35deg);
          background-color: #EB4628;
          border-radius: 100px;
          @media only screen and (max-width: 1024px) {
            top: -5px;
            bottom: 0;
            left: 0;
            right: 0px;
            width: 10px;
          }
        }
        &:after{
          position: absolute;
          margin: auto;
          top: 8px;
          bottom: 0;
          left: 0;
          right: -5px;
          content:"";
          width: 15px;
          height: 1px;
          display: block;
          transform: rotate(-35deg);
          background-color: #EB4628;
          border-radius: 100px;
          @media only screen and (max-width: 1024px) {
            top: 5px;
            bottom: 0;
            left: 0;
            right: 0px;
            width: 10px;
          }
        }
          &:hover{
          background-color: #EB4628;
          opacity: 1;
          &:before{
            background-color: #fff;
          }
          &:after{
            background-color: #fff;
          }
        }
        @media only screen and (max-width: 1024px) {
          width: 26px;
          height: 26px;
				}
				@media screen and (max-width: 599px) {
          width: 25px;
          height: 25px;
				}
      }
      .next{
        width: 40px;
        height: 40px;
        transition: all 0.3s;
        border-radius: 100%;
        border: solid #EB4628 1px;
        position: relative;
        &:before{
          position: absolute;
          margin: auto;
          top: -8px;
          bottom: 0;
          left: 0;
          right: -5px;
          content:"";
          width: 15px;
          height: 1px;
          display: block;
          transform: rotate(35deg);
          background-color: #EB4628;
          border-radius: 100px;
          @media only screen and (max-width: 1024px) {
            top: -5px;
            bottom: 0;
            left: 0;
            right: 0px;
            width: 10px;
          }
        }
        &:after{
          position: absolute;
          margin: auto;
          top: 8px;
          bottom: 0;
          left: 0;
          right: -5px;
          content:"";
          width: 15px;
          height: 1px;
          display: block;
          transform: rotate(-35deg);
          background-color: #EB4628;
          border-radius: 100px;
          @media only screen and (max-width: 1024px) {
            top: 5px;
            bottom: 0;
            left: 0;
            right: 0px;
            width: 10px;
          }
        }
          &:hover{
          background-color: #EB4628;
          opacity: 1;
          &:before{
            background-color: #fff;
          }
          &:after{
            background-color: #fff;
          }
        }
        @media only screen and (max-width: 1024px) {
          width: 26px;
          height: 26px;
				}
				@media screen and (max-width: 599px) {
          width: 25px;
          height: 25px;
				}
      }
    }
  }
  &-list-footer{
    width: 100%;
    height: 335px;
    background: var(--colorYellow);
    @media only screen and (max-width: 768px) {
      height: 235px;
    }
  }

  .pagination{
    margin: 80px auto 0;
    max-width: 312px;
    font-size: 2em;
    font-weight: 500;
    color: var(--colorRed);
    width: 100%;
    @media only screen and (max-width: 768px) {
      max-width: 234px;
    }
  }
}

//シングルページ
.single-news{
  padding: 100px 0 0;
  @media only screen and (max-width: 768px) {
    padding: 50px 0 0;
  }
  &__header{
    position: relative;
    background: var(--colorYellow);

    &__title{
      font-size: 20px;
      padding: 5em 0 6em;
      text-transform: uppercase;
      @media only screen and (max-width: 768px) {
        font-size: 14px;

      }
      h1{
        text-transform: uppercase;
        padding: 0 0 0.5em;
        margin: auto;
        text-align: center;
        width: 5em;
        font-size: 2em;
        font-weight: 500;
        color: var(--colorRed);
        border-bottom: 5px solid var(--colorRed);
      }
    }
  }

  .news-box{
    margin: 0px auto 50px;
    max-width: 1000px;
    width: 100%;
    padding: 0;
    color: var(--colorRed);
    color: var(--mainTextColor);
    &__item{
      color: var(--mainTextColor);
      width: 100%;
      padding: 3em 2em 0;
      font-size: 24px;
      line-height: 1.5em;
      margin: auto;
      border-bottom: 2px solid var(--colorGray);
      @media only screen and (max-width: 768px) {
        font-size: 16px;
      }
      a{
        text-decoration: none;
      }
      &__text{
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
        border-bottom: 2px solid var(--colorGray);
        font-size: 20px;
        line-height: 2em;
        color: var(--mainTextColor);
        @media only screen and (max-width: 768px) {
          font-size: 16px;
          align-items: flex-start;
          justify-content: center;
          flex-direction: column;
        }
        .top-text{
          font-size: 1em;
          display: flex;
          align-items: center;
          font-weight: 500;
          line-height: 1.5em;
          @media only screen and (max-width: 768px) {
            padding: 0 0 1em;
          }
          .date{
            font-size: 1.25em;
            margin: 0 1.5em 0 0;
            letter-spacing: 0.02em;
          }
          .label{
            border-radius: 15px;
            margin: 0 3em 0 0;
            font-size: 0.8em;
            padding: 0.25em 1.5em;
            background: var(--colorRed);
            color: var(--mainTextColorInverted);
            @media only screen and (max-width: 768px) {
              margin: 0;
            }
          }
        }
        .bottom-text{
          font-size: 1.25em;
          letter-spacing: 0.02em;
          vertical-align: middle;
          line-height: 2em;
          padding: 1.5em 0 2em;
          @media only screen and (max-width: 768px) {
            font-size: 1em;
          }
        }
      }
    }
  }

  .back-button{
    font-size: 28px;
    padding: 1em 0 3em;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    @media only screen and (max-width: 768px) {
      font-size: 20px;
    }
    button{
      border-radius: 15px;
      font-size: 28px;
      background: var(--colorYellow);
      @media only screen and (max-width: 768px) {
        font-size: 20px;
      }
      a{
        display: block;
        letter-spacing: 0.09em;
        width: 100%;
        padding: 0.75em 2em;
        border-radius: 15px;
        color: var(--mainTextColor);
      }
    }
  }

  article{
    --articleLineHeight: 2em;
    --articleFontSize: 18px;
    --articleLetterSpacing: 0.0125em
    font-size: var(--articleFontSize);
    line-height: var(--articleLineHeight);
    letter-spacing: var(--articleLetterSpacing);
    padding: 2em 0 3em;
    font-weight: 400;
    @media only screen and (max-width: 400px) {
      font-size: 14px;
    }
    *{
      color: var(--mainTextColor);
      font-size: var(--articleFontSize);
      line-height: var(--articleLineHeight);
      letter-spacing: var(--articleLetterSpacing);
    }
    p{
      font-size: var(--articleFontSize);
      line-height: 2em;
      padding: 0.5em 0 0.5em;
    }
    img{
      padding: 1.5em 0 1.5em;
      object-fit: contain;
    }
    h1{
      font-size: calc( var(--articleFontSize) * 1.5 );
      line-height: 3em;
      font-weight: 500;
    }
    h2{
      font-size: calc( var(--articleFontSize) * 1.4 );
      line-height: 2.75em;
      padding: 0 0 0.825em;
      font-weight: 500;
    }
    h3{
      font-size: calc( var(--articleFontSize) * 1.333 );
      line-height: 2.75em;
      font-weight: 500;
    }
    h4{
      font-size: calc( var(--articleFontSize) * 1.25 );
      line-height: 2.25em;
      font-weight: 500;
    }
    h5{
      font-size: calc( var(--articleFontSize) * 1.125 );
      line-height: 2.0em;
    }
    h6{
      font-size: calc( var(--articleFontSize) * 1.1 );
      line-height: 2.0em;
    }
    ol{
      padding-left: 1em;
      margin-left: 0.5em;
      li{
        list-style: decimal;
      }
    }
    ul{
      padding-left: 1em;
      margin-left: 0.5em;
      li{
        list-style: disc ;
        ul{
          li{
            list-style: circle;
            ul{
              li{
                list-style: disc;
                ul{
                  li{
                    list-style: circle;
                  }
                }
              }
            }
          }
        }
      }
    }
    blockquote{
      font-size: 1em;
      padding: 1em;
      margin: 0.5em;
      background: rgba(204, 204, 204, 0.521);
      cite{
        font-size: initial;
        line-height: 2em;
      }
    }
    table{
      padding: 1em;
      tr{
        td{
          text-align: center;
        }
      }
    }
    a{
      text-decoration: underline;
      font-weight: 500;
      color: var(--colorBlue);
      *{
        text-decoration: underline;
        font-weight: 500;
        color: var(--colorBlue);
      }
    }
    .wp-block-media-text__media{
      img{
        padding: 0;
      }
    }
    .wp-block-media-text__content{
      @media only screen and (max-width: 400px) {
        padding: 0;
      }
    }
  }
}

.bottom-liner{
  background: var(--colorYellow);
  display: flex;
  align-items: center;
  justify-content: center;
  @media only screen and (max-width: 768px) {

  }
}
.illustration{
  img{
    display: block;
    height: 180px;
    margin: 0 auto;
    @media only screen and (max-width: 1024px) {
      height: 267px;
    }
    @media only screen and (max-width: 768px) {
      height: 115px;
    }
  }
}
.wp-block-image img{
  height: 100% !important;
}

