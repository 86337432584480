.banner-user-review {
	background: var(--mainBGColorYellow);
	padding: 0 0 150px;
	.BannerBox {
		max-width: 100vw;
	}
	@media only screen and (max-width: 1024px) {
		padding: 0;
		.BannerBox {
			width: 100vw;
		}
	}
	@media only screen and (max-width: 599px) {
		width: 100vw;
		background: var(--colorWhite);
		.BannerBox {
			width: 100vw;
		}
	}
	.H2__title {
		padding: 2em 0 2em;
		font-size: 50px;
		color: var(--colorRed);
		@media only screen and (max-width: 1024px) {
			font-size: 24px;
			padding: 2em 0 1em;
		}
		@media only screen and (max-width: 599px) {
			background: var(--mainBGColorYellow);
			width: 100vw;
		}
		span {
			color: var(--colorRed);
			@media only screen and (max-width: 1024px) {
				font-size: 42px;
			}
			@media only screen and (max-width: 599px) {
				font-size: 1em;
			}
		}
		div {
			color: var(--colorRed);
			background: var(--colorRed);
			&::before {
				color: var(--colorRed);
				background: var(--colorRed);
			}
		}
	}
	&__box {
		.user-review {
			margin: auto;
			font-size: 50px;
			padding: 0 0 2em;
			@media only screen and (max-width: 1024px) {
				font-size: 22px;
				padding: 0;
				background-color: #fff;
			}
			@media only screen and (max-width: 599px) {
				width: 100vw;
				padding: 1em 0 0;
				&:first-of-type {
					background: var(--mainBGColorYellow);
				}
			}
			&__title {
				margin: auto;
				font-size: 40px;
				color: var(--colorWhite);
				background: var(--colorRed);
				border-radius: 50px 50px 0 0;
				padding: 0.75em;
				letter-spacing: 0.25em;
				max-width: 750px;
				text-align: center;
				@media only screen and (max-width: 1024px) {
					font-size: 20px;
					border-radius: 1em 1em 0 0;
					width: 88vw;
					max-width: 400px;
				}
				span {
					font-size: 36px;
					@media only screen and (max-width: 1024px) {
						font-size: 18px;
					}
				}
			}
			&__list {
				font-size: 32px;
				padding: 90px 20px;
				border: 10px solid var(--colorRed);
				background: var(--colorWhite);
				@media only screen and (min-width: 1024px) {
					display: flex;
					flex-wrap: wrap;
					justify-content: center;
					column-gap: 25px;
				}
				@media only screen and (max-width: 1024px) {
					border-top: 4px solid var(--colorRed);
					border-left: none;
					border-right: none;
					border-bottom: none;
					padding: 20px 0 60px;
					font-size: 16px;
				}
				@media only screen and (max-width: 599px) {
					border-top: 4px solid var(--colorRed);
					border-left: none;
					border-right: none;
					border-bottom: none;
					padding: 20px 20px 60px;
				}
				&__item {
					margin: 0 0 100px;
					@media only screen and (min-width: 1024px) {
						width: 48%;
					}
					@media only screen and (max-width: 1024px) {
						margin: 0 0 40px;
					}
					&:last-child {
						margin: 0;
					}
					.item-box {
						width: 100%;
						background: var(--colorAmber);
						padding: 1.25em 1.5em 0.75em;
						border-radius: 60px;
						position: relative;
						@media only screen and (max-width: 1024px) {
							border-radius: 30px;
							padding: 1em 1.5em 0.5em;
							justify-content: center;
							max-width: 555px;
							margin: auto;
							display: flex;
							justify-content: flex-start;
							flex-wrap: wrap;
							@media only screen and (max-width: 599px) {
								padding: 1em 1em 0.75em;
							}
						}
						&::after {
							content: "";
							position: absolute;
							bottom: -42px;
							width: 0;
							height: 0;
							left: calc(var(--MainContentMaxWidthPC) / 2 + -400px / 2);
							right: 0;
							margin: auto;
							border-style: solid;
							border-width: 42px 33.5px 0 33.5px;
							border-color: var(--colorAmber) transparent transparent
								transparent;
							@media only screen and (max-width: 1024px) {
								left: calc(var(--MainContentMaxWidthPC) / 2 + 90px / 2);
								position: absolute;
								bottom: -16px;
								width: 0;
								height: 0;
								left: 230px;
								right: 0;
								margin: auto;
								border-style: solid;
								border-width: 16px 12.5px 0 12.5px;
								border-color: var(--colorAmber) transparent transparent
									transparent;
							}
						}
						.parents-icons {
							width: 126px;
							@media only screen and (min-width: 1024px) {
								position: absolute;
								top: 53px;
								height: 186px;
								width: auto;
								left: 10%;
							}
							@media only screen and (max-width: 1024px) {
								position: absolute;
								height: 150px;
								top: 35px;
								left: 45px;
							}
							@media only screen and (max-width: 599px) {
								position: static;
								margin: 0 auto 5px;
								height: 70px;
								width: 47px;
							}
							@media only screen and (max-width: 374px) {
								margin: 0 auto 0;
							}
						}
						.description {
							margin: 0 0 0 2em;
							color: var(--text);
							display: flex;
							justify-content: space-between;
							align-items: center;
							flex-direction: column;
							@media only screen and (min-width: 1024px) {
								font-size: 26px;
								margin: 0 0 0 5em;
							}
							@media only screen and (max-width: 1024px) {
								font-size: 28px;
								justify-content: center;
								margin: 0 auto 0 6em;
								min-width: 200px;
							}
							@media only screen and (max-width: 599px) {
								font-size: 16px;
								margin: 0 auto 0 0.75em;
							}
							@media only screen and (max-width: 374px) {
								font-size: 14px;
							}
							.case {
								margin: auto;
								text-align: inherit;
								width: 100%;
								display: contents;
								@media only screen and (max-width: 1024px) {
									text-align: center;
								}
								&-courseName {
									font-size: 23px;
									display: contents;
									@media only screen and (max-width: 599px) {
										font-size: 16px;
									}
									@media only screen and (max-width: 374px) {
										font-size: 14px;
									}
								}
							}
						}
						label {
							width: 100%;
							color: var(--colorRed);
							text-align: center;
							cursor: pointer;
							@media only screen and (max-width: 599px) {
								font-size: 14px;
							}
						}
						// アコーディオンパネル
						.acd-check {
							display: none;
						}
						.acd-label {
							display: block;
							margin-bottom: 1px;
							padding: 10px 30px 10px 10px;
							text-align: end;
							@media only screen and (max-width: 1024px) {
								font-size: 28px;
								text-align: center;
								margin: 0px 0 0px 70px;
							}
							@media only screen and (max-width: 599px) {
								font-size: 16px;
								padding: 0 0 0.5em;
								margin: 0;
							}
						}
						.acd-content {
							// labelのmargin-rightと同値
							height: 0;
							opacity: 0;
							transition: all 0.5s ease-in-out;
							height: 0;
							visibility: hidden;
							overflow: hidden;
							p {
								color: var(--colorBlack);
								font-size: 26px;
								letter-spacing: 0.125em;
								line-height: 1.75em;
								text-align: justify;
								@media only screen and (max-width: 1024px) {
									font-size: 24px;
								}
								@media only screen and (max-width: 599px) {
									font-size: 14px;
								}
							}
						}
						.acd-check:checked + .acd-label + .acd-content {
							height: fit-content;
							margin: 1em 0 1em 0;
							opacity: 1;
							visibility: visible;
							overflow: hidden;
							height: auto;
						}
						.acd-check:checked + .acd-label {
							span {
								transform: rotate(180deg);
							}
						}
					}
				}
			}
		}
	}
	.bg-yellow {
		background-color: #fff51e;
	}
}
