// ===========================
// Reset
// ===========================
input,
button,
textarea,
select {
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  border-radius: 0;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
button {
  -webkit-appearance: button; /* iOS 8+ */
  overflow: visible; /* Internet Explorer 11- */
}
input {
  -webkit-border-radius: 0; /* iOS 8+ */
}

input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button; /* iOS 8+ */
}

input[type="number"] {
  width: auto; /* Firefox 36+ */
}

input[type="search"] {
  -webkit-appearance: textfield; /* Chrome 45+, Safari 9+ */
}

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; /* Chrome 45+, Safari 9+ */
}
button,
input,
select,
textarea {
  background-color: transparent;
}
button,
input,
select,
textarea {
  color: inherit;
  font-family: inherit;
  font-style: inherit;
  font-weight: inherit;
}
button,
[type="button"],
[type="date"],
[type="datetime"],
[type="datetime-local"],
[type="email"],
[type="month"],
[type="number"],
[type="password"],
[type="reset"],
[type="search"],
[type="submit"],
[type="tel"],
[type="text"],
[type="time"],
[type="url"],
[type="week"],
select,
textarea {
  min-height: 1.5em;
}
button,
input,
label,
select,
textarea,
[tabindex] {
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}
/*===============================================

 *  ブラウザリセット

===============================================*/

html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

html {
  font-size: 62.5%; /*10px*/
  overflow-y: scroll;
}

body {
  font-size: 1.4rem; /*14px*/
  font-family: sans-serif;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

select,
input,
textarea {
  font: 99% arial, helvetica, clean, sans-serif;
}

pre,
code {
  font: 115% monospace;
  *font-size: 100%;
}

br {
  letter-spacing: normal;
}

body,
div,
p,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
form,
fieldset,
input,
textarea,
p,
blockquote,
th,
td,
img,
figure,
figcaption {
  margin: 0;
  padding: 0;
  line-height: 140%;
  font-style: normal;
  font-weight: 400;
}

p,
li,
dt,
dd {
  font-size: 100％;
}

address,
em {
  font-style: normal;
}

strong,
th {
  font-weight: 400;
}

table {
  font-size: inherit;
  border-collapse: collapse;
  border-spacing: 0;
  border: none;
}

th,
td {
  text-align: left;
  border: none;
  font-weight: 400;
}

hr {
  display: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
}

img,
fieldset {
  border: 0;
  vertical-align: bottom;
}

li {
  list-style-type: none;
}

ul,
ol,
dl {
  list-style-position: outside;
  list-style-type: none;
}

dt {
  font-weight: 400;
}

a {
  text-decoration: none;
  outline: none;
}

form,
input {
  margin: 0;
  padding: 0;
}
