.faq {
	font-family: fot-tsukuardgothic-std, sans-serif;
	padding: 100px 0 0;
	@media only screen and (max-width: 768px) {
		padding: 50px 0 0;
	}
	.DotsLine {
		--dotsLineWidth: 215px;
		border-color: var(--colorRed);
		@media only screen and (max-width: 768px) {
			--dotsLineWidth: 180px;
		}
	}
	.content-faq {
		padding: 0 0 0px;
		&__middle-box {
			background-size: 80px 80px;
			position: relative;
			z-index: 5;
			box-shadow: 0 10px 25px 0 rgba(235, 235, 235, 0.3);
			h1 {
				letter-spacing: 0.3em;
			}
		}
		.faq-box {
			margin: 0px auto 0px;
			padding: 0 0 80px;
			&__wrap {
				display: flex;
				flex-wrap: wrap;
			}
			&__item {
				color: var(--mainTextColorInverted);
				width: 45%;
				font-size: 24px;
				line-height: 1.5em;
				margin: 2em auto 1em;
				text-align: justify;
				@media only screen and (max-width: 1024px) {
					width: 100%;
					font-size: 27px;
				}
				@media only screen and (max-width: 768px) {
					width: 100%;
					font-size: 16px;
				}
				.item-box {
					background: #eb4628;
					border-radius: 60px;
					padding: 0.75em;
					@media only screen and (max-width: 768px) {
						padding: 1em;
					}
				}
			}
			.odd {
				background: var(--colorPaleRed);
				color: var(--mainTextColorInverted);
			}
			.even {
				background: var(--colorPaleYellow);
				color: var(--mainTextColor);
			}
			p {
				.faq-title {
					font-size: 2em;
					line-height: 1.5em;
				}
			}
			.freeTrial {
				margin: 80px 0 0 0;
				text-align: center;
				@media only screen and (max-width: 1024px) {
				}
				@media only screen and (max-width: 768px) {
					margin: 0;
				}
				.TitleHeader__MainTitle {
					font-size: 50px;
					margin: 0 0 20px 0;
					@media only screen and (max-width: 768px) {
						font-size: 27px;
					}
				}
				.line-button {
					img {
						@media only screen and (max-width: 1024px) {
							width: 100%;
						}
					}
				}
			}
		}
	}
	.question {
		display: flex;
		align-items: center;
	}
	label {
		width: 100%;
		text-align: center;
		cursor: pointer;
		@media only screen and (max-width: 1024px) {
		}
		@media only screen and (max-width: 768px) {
			font-size: 15px;
		}
	}
	// アコーディオンパネル
	.acd-check {
		display: none;
	}
	.acd-label {
		display: block;
		margin-bottom: 1px;
		padding: 10px;
		@media only screen and (max-width: 1024px) {
		}
		@media only screen and (max-width: 768px) {
			padding: 0;
		}
	}
	.acd-content {
		// labelのmargin-rightと同値
		height: 0;
		opacity: 0;
		transition: all 0.5s ease-in-out;
		height: 0;
		visibility: hidden;
		overflow: hidden;
		.answer {
			font-size: 20px;
			letter-spacing: 0.125em;
			line-height: 1.75em;
			text-align: justify;
			@media only screen and (max-width: 1024px) {
				font-size: 22px;
			}
			@media only screen and (max-width: 768px) {
				font-size: 16px;
			}
			span {
				white-space: break-spaces;
			}
		}
	}
	.acd-check:checked + .acd-label + .acd-content {
		height: fit-content;
		margin: 1em 0 1em 0;
		opacity: 1;
		visibility: visible;
		overflow: hidden;
		height: auto;
	}
	.line-bannr {
		height: 80vh;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		background-position: center;
		@media only screen and (max-width: 1024px) {
		}
		@media only screen and (max-width: 768px) {
			height: 94vh;
		}
		a {
			margin: auto;
			display: block;
			@media only screen and (max-width: 1024px) {
			}
			@media only screen and (max-width: 768px) {
				width: 80%;
			}
			img {
				display: block;
				width: 100%;
				box-shadow: 0 10px 25px 0 rgba(235, 235, 235, 0.3);
			}
		}
	}
}
