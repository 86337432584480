.banner10{
  padding: 80px 0 70px;
  position: relative;
  z-index: 8;
  box-shadow: 0 10px 25px 0 rgba(0, 0, 0, .5);
  background-image: linear-gradient(
    -45deg, 
    var(--mainBGColorYellow) 25%,
    var(--mainBGColorLightYellow) 25%, var(--mainBGColorLightYellow) 50%,
    var(--mainBGColorYellow) 50%, var(--mainBGColorYellow) 75%,
    var(--mainBGColorLightYellow) 75%, var(--mainBGColorLightYellow) 100%
  );
  background-size: 50px 50px;
  &__box{
    display: flex;
    justify-content: center;
    flex-direction: column;
    .H2__title{
      --H2TitleColor: var(--mainBGColorRed);
    }
    .fee-plan{
      &__courses{
        margin: 80px auto 0px;
        width: 100%;
        height: auto;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        @media only screen and (max-width: 1000px) {
          flex-direction: column;
          align-items: center;
        }
        @media only screen and (max-width: 500px) {
          margin: 40px auto 0;
        }
        .divider{
          width: 1px;
          height: 570px;
          margin: 10% auto 0;
          background: var(--colorRed);
          @media only screen and (max-width: 1000px) {
            display: none;
          }
        }
        &__item{
          width: 50%;
          max-width: 445px;
          height: auto;
          @media only screen and (max-width: 1000px) {
            margin: 0 0 75px;
            width: 100%;
            max-width: 100%;
          }
          @media only screen and (max-width: 500px) {
            width: 100%;
          }
          h3{
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            width: 100%;
            height: 80px;
            font-weight: 600;
            background: var(--colorGreen);
            font-size: 42px;
            text-align: center;
            position: relative;
            margin: 0 auto calc( 40px + 1.25em );
            color: var(--mainTextColorInverted);
            border-radius: 5px;
            @media only screen and (max-width: 1000px) {
              width: 100%;
              @media only screen and (max-width: 500px) {
                height: 60px;
                font-size: 24px;
              }
            }
            &::after{
              content: "";
              position: absolute;
              border-color: var(--colorGreen) transparent transparent transparent;
              top: initial;
              bottom: -39px;
              left: 0;
              right: initial;
              border-style: solid;
              border-width: 40px 222.5px 0 222.5px;
              border-radius: 5px;
              @media only screen and (max-width: 1000px) {
                width: 100%;
                bottom: -24px;
                border-width: 25px calc(45vw - 0px) 0 calc(45vw - 0px);
                @media only screen and (max-width: 767px) {

                }
              }
            }
          }
          h4{
            text-align: center;
            font-size: 42px;
            font-weight: 600;
            color: var(--colorGreen);
            margin: 0 0 0.75em;
            @media only screen and (max-width: 500px) {
              font-size: 24px;
            }
            span{
              font-size: 0.6em;
            }
          }
          .Badge{
            position: relative;
            color: var(--mainTextColorInverted);
            img{
              width: 110px;
              @media only screen and (max-width: 500px) {
                width: 88px;
              }
            }
            &__text{
              top: -5px;
              bottom: 0;
              right: 0;
              left: 0;
              margin: auto;
              width: 110px;
              position: absolute;
              font-size: 30px;
              display: inline-flex;
              align-items: center;
              justify-content: center;
              flex-direction: column;
              line-height: 1.0em;
              @media only screen and (max-width: 500px) {
                width: 88px;
                font-size: 22px;
              }
              .text-top{
                font-size: 0.73em;
              }
              .text-top-tiny{
                font-size: 0.6em;
              }
              .text-bottom{
                font-size: 1.0em;
              }
            }
          }
          .price-box{
            display: flex;
            justify-content: center;
            align-items: flex-start;
            margin: 25px 0 50px;
            @media only screen and (max-width: 500px) {
              margin: 18px 0 36px;
            }
          }

          .fee{
            margin: 0 0 0 0.3em;
            font-size: 83px;
            color: var(--mainTextColor);
            display: inline-flex;
            justify-content: flex-start;
            align-items: flex-end;
            flex-direction: column;
            letter-spacing: 0.01em;
            white-space: nowrap;
            width: 310px;
            @media only screen and (max-width: 500px) {
              font-size: 62px;
              width: 230px;
            }
            &__raw{
              font-size: 1em;
              font-weight: 600;
              line-height: 100%;
              position: relative;
              .yen{
                font-size: 0.5em;
                font-weight: 300;
              }
              &::after{
                content: "";
                position: absolute;
                bottom: 0.2em;
                z-index: -1;
                left: 0;
                height: 0.1em;
                width: 100%;
                background: var(--colorGreen);
              }
            }
            &__including-tax{
              line-height: 100%;
              font-size: 0.5em;
            }
          }

          .next-month{
            .square{
              width: 95px;
              @media only screen and (max-width: 500px) {
                width: 72px;
              }
            }
            .Badge__text{
              width: 95px;
              @media only screen and (max-width: 500px) {
                width: 72px;
              }
            }
            .fee__raw::after{
              content: none;
            }
          }

        }
        .normal{
          h3{
            background: var(--colorGreen);
            &::after{
              border-color: var(--colorGreen) transparent transparent transparent;
            }
          }
          h4{
            color: var(--colorGreen);
          }
          .fee__raw{
            &::after{
              background: var(--colorOrange);
            }
          }
        }
        .special{
          h3{
            background: var(--colorOrange);
            &::after{
              border-color: var(--colorOrange) transparent transparent transparent;
            }
          }
          h4{
            color: var(--colorOrange);
          }
          .fee__raw{
            &::after{
              background: var(--colorOrange);
            }
          }
        }
      }
      &__admission{
        .arrow-down{
          display: flex;
          justify-content: center;
          flex-direction: column;
          align-items: center;
          width: 445px;
          height: 80px;
          font-weight: 600;
          background: var(--colorRed);
          font-size: 42px;
          text-align: center;
          position: relative;
          margin: 0 auto calc( 40px + 0.5em );
          color: var(--mainTextColorInverted);
          border-radius: 5px;
          @media only screen and (max-width: 1000px) {
            width: 100%;
            @media only screen and (max-width: 500px) {
              height: 60px;
              font-size: 24px;
              margin: 0 auto calc( 25px + 0.5em );
            }
          }
          &::after{
            content: "";
            position: absolute;
            border-color: var(--colorRed) transparent transparent transparent;
            top: initial;
            bottom: -40px;
            left: 0;
            right: initial;
            border-style: solid;
            border-width: 40px 222.5px 0 222.5px;
            border-radius: 5px;
            @media only screen and (max-width: 1000px) {
              width: 100%;
              bottom: -24px;
              border-width: 25px calc(45vw - 0px) 0 calc(45vw - 0px);
              @media only screen and (max-width: 500px) {

              }
            }
          }
        }
        &__text{
          font-weight: 600;
          font-size: 88px;
          color: var(--colorRed);
          letter-spacing: 0.1em;
          text-align: center;
        }
      }
    }

    .link-button{
      margin: 60px auto 40px;
      display: block;
      font-size: 24px;
      padding: 1em 0;
      border-radius: 25px;
      width: 100%;
      max-width: 1000px;
      background: white;
      color: var(--mainTextColor);
      text-align: center;
      @media only screen and (max-width: 1000px) {
        max-width: 500px;
      }
      @media only screen and (max-width: 767px) {
        font-size: 20px;
        margin: 30px 0 20px;
        max-width: auto;
      }
    }
  }

}