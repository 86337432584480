.banner01 {
	position: relative;
	background-color: #349d4b;
	box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.5);

	position: relative;
	box-shadow: 0 10px 25px 0 rgb(0 0 0 / 50%);
	@media only screen and (max-width: 1024px) {
		padding: 0 0 5px;
	}
	@media only screen and (max-width: 599px) {
		padding: 0 0 5px;
	}
	.banner01__box {
		// display: flex;
		// align-items: flex-start;
		// justify-content: space-between;
		// position: relative;
		// margin: 0 auto 30px;
		.Left {
			.reception {
				margin: 1em 0 0;
				letter-spacing: 0.125em;
				width: 100%;
				font-size: 30px;
				padding: 0.1em;
				border-radius: 1em;
				background: var(--mainBGColorRed);
				color: var(--mainBGColorYellow);
				font-family: tbudrgothic-std, sans-serif;
				font-weight: 700;
				font-style: normal;
				text-align: center;
				@media only screen and (max-width: 1024px) {
					font-size: 14px;
				}
				@media only screen and (max-width: 599px) {
					font-size: 14px;
				}
			}
		}
		.Right {
			display: flex;
			align-items: center;
			justify-content: space-between;
			flex-direction: column;
		}
		@media only screen and (max-width: 1024px) {
			.Left {
				width: 60%;
				img {
					width: 100%;
				}
			}
			.Right {
				width: 40%;
				img {
					width: 100%;
				}
			}
		}
		@media only screen and (max-width: 599px) {
			.Left {
				width: 60%;
				img {
					width: 100%;
				}
			}
			.Right {
				width: 40%;
				img {
					width: 100%;
				}
			}
		}
	}
	.LineBanner {
		width: 100vw;
		background: var(--mainBGColorRed);
		position: absolute;
		bottom: 0px;
		padding: 30px 10px;
		&__box {
			margin: auto;
			z-index: 1;
			display: flex;
			align-items: center;
			justify-content: space-between;
			max-width: var(--MaxWidthPC);
		}
		.Left {
			color: white;
			.campaign {
				font-weight: 500;
				font-size: 42px;
				&__tiny-text {
					font-size: 0.5em;
				}
				@media only screen and (max-width: 1024px) {
					font-size: 20px;
				}
				@media only screen and (max-width: 599px) {
					font-size: 20px;
				}
			}
		}
		.Right {
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			color: white;
			.coupon {
				display: inline-flex;
				align-items: center;
				justify-content: center;
				border: 3px white solid;
				border-radius: 10px;
				vertical-align: middle;
				padding: 0.25em 1.5em 0.5em;
				@media only screen and (max-width: 1024px) {
					padding: 0.25em 1em 0.5em;
				}
				a {
					color: white;
					padding: 0.5em 0.25em;
					font-size: 28px;
					@media only screen and (max-width: 1024px) {
						font-size: 22px;
					}
					@media only screen and (max-width: 599px) {
						font-size: 22px;
					}
				}
				.gift-card {
					white-space: nowrap;
					display: inline-flex;
					align-items: flex-start;
					justify-content: flex-start;
					flex-direction: column;
					font-weight: 600;
					letter-spacing: 0.25em;
					line-height: 1.5em;
					padding: 0 5px 0 0;
					@media only screen and (max-width: 1024px) {
						letter-spacing: 0.025em;
						line-height: 1.5em;
					}
					@media only screen and (max-width: 599px) {
						letter-spacing: 0.025em;
						line-height: 1.5em;
					}
					&__top {
						font-size: 14px;
						@media only screen and (max-width: 1024px) {
							font-size: 12px;
						}
						@media only screen and (max-width: 599px) {
							font-size: 12px;
						}
					}
					&__bottom {
						font-size: 28px;
						@media only screen and (max-width: 1024px) {
							font-size: 22px;
						}
						@media only screen and (max-width: 599px) {
							font-size: 22px;
						}
					}
				}
				.price {
					white-space: nowrap;
					font-size: 58px;
					line-height: 1em;
					padding: 0 0 0 5px;
					font-family: "Oswald", "NotoSansCJKjp", "sans-serif";
					font-weight: 400;
					font-style: normal;
					@media only screen and (max-width: 1024px) {
						font-size: 40px;
					}
					@media only screen and (max-width: 599px) {
						font-size: 40px;
					}
				}
			}
			.bannerText {
				color: white;
				font-size: 12px;
				padding: 10px 0 0;
				align-self: flex-end;
			}
		}
	}
	@media only screen and (max-width: 1024px) {
		.LineBanner {
			padding: 0;
			position: static;
			width: 90vw;
			margin: auto;
		}
		.LineBanner__box {
			flex-direction: column;
			padding: 15px 0 15px;
			.Right {
				margin: 20px 0 0;
				img {
					width: 70vw;
				}
			}
		}
	}
	@media only screen and (max-width: 599px) {
		.LineBanner {
			padding: 0;
			position: static;
			width: 90vw;
			margin: auto;
		}
		.LineBanner__box {
			flex-direction: column;
			padding: 15px 0 15px;
			.Right {
				margin: 20px 0 0;
				img {
					width: 70vw;
				}
			}
		}
	}
	.top-img {
		&__default {
			width: 100%;
			max-width: 1440px;
			height: auto;
			display: block;
			margin: auto;
		}
		&__pc {
			width: 100vw;
			max-width: 1440px;
			height: auto;
			display: block;
			margin: auto;
		}
		&__sp {
			width: 100vw;
			max-width: 767px;
			height: auto;
			display: block;
			margin: auto;
		}
	}
	.courses {
		margin: auto;
		max-width: 1000px;
		font-size: 32px;
		display: flex;
		justify-content: center;
		flex-direction: column;
		@media only screen and (max-width: 1024px) {
			font-size: 22px;
		}
		@media only screen and (max-width: 599px) {
			font-size: 22px;
		}
		h2 {
			font-size: 1em;
			color: var(--colorYellow);
			line-height: 1.5em;
			padding: 5rem 0;
			text-align: center;
			@media only screen and (max-width: 1024px) {
				&:before {
					content: "";
				}
				&::after {
					content: "";
				}
				font-size: 1em;
			}
			@media only screen and (max-width: 599px) {
				&:before {
					content: "";
				}
				&::after {
					content: "";
				}
				font-size: 1em;
			}
		}
		&__box {
			display: flex;
			justify-content: space-evenly;
			text-align: center;
			@media only screen and (max-width: 1024px) {
				flex-wrap: wrap;
				align-items: center;
				justify-content: center;
				width: 100%;
			}
			@media only screen and (max-width: 599px) {
				flex-wrap: wrap;
				align-items: center;
				justify-content: center;
				width: 100%;
				padding: 0 0 1em 0;
			}
			&__button {
				@media only screen and (max-width: 1024px) {
					width: 33%;
					margin: 0 auto;
					text-align: center;
				}
				@media only screen and (max-width: 599px) {
					width: 90%;
					margin: 0 auto;
					text-align: center;
				}
				@media only screen and (max-width: 374px) {
					width: 100%;
				}
				img {
					max-width: 309px;
					@media only screen and (max-width: 1024px) {
						max-width: 220px;
					}
					@media only screen and (max-width: 599px) {
						max-width: 90%;
					}
				}
			}
			&__enButton {
				padding: 1em 0 0 0;
				@media only screen and (max-width: 1024px) {
					margin: 0 auto;
					text-align: center;
				}
				@media only screen and (max-width: 599px) {
					width: 90%;
					margin: 0 auto;
					text-align: center;
				}
				@media only screen and (max-width: 374px) {
					width: 100%;
				}
				img {
					width: 100%;
					@media only screen and (max-width: 1024px) {
						max-width: 660px;
					}
					@media only screen and (max-width: 599px) {
						max-width: 100%;
					}
				}
			}
		}
		.line-button {
			padding: 1.5em 0 2em;
			margin: auto;
			@media only screen and (max-width: 599px) {
				padding: 0.5em 0 2em;
			}
			img {
				@media only screen and (max-width: 1024px) {
					max-width: 600px;
				}
			}
		}
	}
}
