.banner02 {
	background-size: 20px px;
	background-position: 50% 50%;
	background-image: repeating-linear-gradient(
			90deg,
			var(--mainBGGridPalePink),
			var(--mainBGGridPalePink) 1px,
			transparent 1px,
			transparent 20px
		),
		repeating-linear-gradient(
			0deg,
			var(--mainBGGridPalePink),
			var(--mainBGGridPalePink) 1px,
			#fff 1px,
			#fff 20px
		);
	padding: 60px 0 110px;
	position: relative;
	box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.5);
	@media only screen and (max-width: 1024px) {
		padding: 50px 0 60px;
	}
	@media only screen and (max-width: 599px) {
		padding: 50px 0 60px;
	}
	&__box {
		margin: auto;
		display: flex;
		justify-content: center;
		flex-direction: column;
		align-items: center;
		@media only screen and (max-width: 599px) {
			width: 91vw;
		}
		&-bottom {
			margin-bottom: 100px;
		}
		p {
			text-align: center;
			line-height: 2em;
			margin: 70px 0 0;
		}
		h2 {
			// max-width: 600px;
			@media only screen and (max-width: 1024px) {
				width: 80vw;
				img {
					width: 100%;
				}
				div {
					margin: 45px 5px 0;
				}
			}
			@media only screen and (max-width: 599px) {
				width: 80vw;
				img {
					width: 100%;
				}
				div {
					margin: 45px 5px 0;
				}
			}
		}
		.video-frame {
			display: contents;
			margin: auto;
			padding: 20px 20px;
			background: #fff;
			border-radius: 10px;
			@media only screen and (max-width: 1024px) {
				padding: 10px;
			}
			&__inner {
				margin: auto;
				width: 100%;
				height: auto;
				border-radius: 10px;
				position: relative;
				overflow: hidden;
				@media only screen and (max-width: 1024px) {
					width: 100%;
					height: auto;
				}
			}
			&__wrap {
				padding: 56.25% 0 0 0;
				position: relative;
				iframe {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					border-radius: 10px;
				}
			}
		}
		.text01 {
			font-size: 32px;
			line-height: 1.38em;
		}
		.text02 {
			line-height: 1.7em;
			font-size: 50px;
			font-weight: 600;
			position: relative;
			z-index: 1;
			.idea {
				position: absolute;
				z-index: -1;
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;
				margin: auto;
				@media only screen and (max-width: 1024px) {
					max-width: 210px;
				}
				@media only screen and (max-width: 599px) {
					max-width: 150px;
				}
			}
			.owl {
				position: absolute;
				width: 120px;
				z-index: 1;
				top: 0;
				bottom: 0;
				//left: 0;
				right: -150px;
				transform: rotate(15deg);
				margin: auto;
				@media only screen and (max-width: 1024px) {
					width: 47px;
					right: -50px;
					top: 142px;
				}
				@media only screen and (max-width: 599px) {
					width: 47px;
					right: -50px;
					top: 142px;
				}
			}
		}
		.text03 {
			line-height: 1.7em;
			font-size: 50px;
			font-weight: 600;
			position: relative;
			z-index: 1;
			.idea {
				position: absolute;
				z-index: -1;
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;
				margin: auto;
				@media only screen and (max-width: 1024px) {
					max-width: 210px;
				}
				@media only screen and (max-width: 599px) {
					max-width: 150px;
				}
			}
		}
		.description-text01 {
			font-size: 22px;
			line-height: 2.5em;
		}
		.photo {
			width: 100%;
			max-width: 500px;
			padding: 2em 0 0;
			img {
				width: 100%;
			}
		}
		@media only screen and (max-width: 1024px) {
			p {
				margin: 40px 0 0;
			}
			div {
				margin: 30px 0 0;
			}
			.text01 {
				font-size: 32px;
				text-align: center;
			}
			.text02 {
				font-size: 50px;
			}
			.text03 {
				margin-top: 70px;
				font-size: 50px;
			}
			.description-text01 {
				text-align: center;
				font-size: 22px;
			}
		}
		@media only screen and (max-width: 599px) {
			p {
				margin: 40px 0 0;
			}
			div {
				margin: 30px 0 0;
			}
			.text01 {
				font-size: 18px;
				text-align: center;
			}
			.text02 {
				font-size: 25px;
			}
			.text03 {
				margin-top: 70px;
				font-size: 25px;
			}
			.description-text01 {
				text-align: justify;
				font-size: 14px;
			}
		}
		//フクロウ
		.owl-description {
			display: flex;
			align-items: center;
			justify-content: center;
			@media only screen and (max-width: 1024px) {
				flex-direction: column;
			}
			.owl {
				margin: auto;
				height: 180px;
			}
			.fukidashi {
				margin: 0 50px 50px 0;
				position: relative;
				width: 560px;
				background: #ffffff;
				padding: 45px 70px;
				text-align: justify;
				border: 1px solid #ffffff;
				color: var(--mainTextColor);
				font-size: 18px;
				border-radius: 100px;
				@media only screen and (max-width: 1024px) {
					text-align: justify;
					width: 100%;
					font-size: 14px;
					margin: 0 0 40px;
					border-radius: 40px;
					padding: 30px 30px;
				}
			}
			.fukidashi:after,
			.fukidashi:before {
				border: solid transparent;
				content: "";
				height: 0;
				width: 0;
				pointer-events: none;
				position: absolute;
				border-color: rgba(0, 153, 255, 0);
				border-top-width: 10px;
				border-bottom-width: 10px;
				border-left-width: 20px;
				border-right-width: 20px;
				margin-top: -10px;
				border-left-color: white;
				left: 100%;
				top: 50%;
				@media only screen and (max-width: 1024px) {
					border: solid transparent;
					content: "";
					height: 0;
					width: 0;
					pointer-events: none;
					position: absolute;
					left: 0;
					right: 0;
					margin: 0 auto;
					top: 100%;
					width: 0;
					height: 0;
					border-style: solid;
					border-width: 20px 10px 0 10px;
					border-color: #ffffff transparent transparent transparent;
				}
			}
		}

		h2 {
			--H2TitleColor: var(--mainBGColorRed);
			span {
				padding: 1.5em 0.25em 0;
				@media only screen and (max-width: 1024px) {
					font-size: 42px;
				}
				@media only screen and (max-width: 599px) {
					font-size: 1em;
				}
			}
		}
		.panels {
			margin: 30px 0 0;
			display: flex;
			justify-content: center;
			@media only screen and (max-width: 1024px) {
				flex-wrap: wrap;
				max-width: 500px;
			}
			&__item {
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 18px;
				width: 100%;
				height: auto;
				width: 200px;
				height: 200px;
				padding: 1em 1em;
				border-radius: 50%;
				margin: 20px;
				text-align: center;
				&__description {
					margin: 0;
					padding: 0;
					line-height: 2em;
				}
				@media only screen and (max-width: 1024px) {
					padding: 1em 1em;
					max-width: 240px;
					max-height: 240px;
					&__description {
						line-height: 1.75em;
					}
					font-size: 14px;
					width: 160px;
					height: 160px;
					text-align: justify;
					vertical-align: middle;
					display: flex;
					justify-content: center;
					align-items: center;
					flex-direction: column;
					&__description {
						text-align: center;
						line-height: 1.5em;
					}
					&:nth-child(2n-1) {
						margin: 7px 7px 7px 0;
						@media only screen and (max-width: 599px) {
							margin: 10px;
						}
					}
					&:nth-child(2n) {
						margin: 7px 0 7px 7px;
						@media only screen and (max-width: 599px) {
							margin: 10px;
						}
					}
					@media only screen and (max-width: 599px) {
						margin: 10px;
					}
				}
				@media only screen and (max-width: 599px) {
					padding: 1em 1em;
					max-width: 500px;
					width: 156px;
					height: 156px;
					&__description {
						line-height: 1.75em;
					}
				}
				.small-text {
					font-size: 0.7em;
				}
			}
		}
	}
	.learningEffect {
		width: 100%;
		max-width: 100vw;
		background-color: #fff;
		text-align: center;
		img {
			width: 100%;
			max-width: 700px;
		}
	}
	.stateOfTheLesson {
		width: 100%;
		max-width: 100vw;
		margin: 70px 0 0 0;
		text-align: center;
		img {
			width: 100%;
			max-width: 700px;
		}
	}
}
