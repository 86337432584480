.banner-news {
	background: var(--colorWhite);
	padding: 80px 0 70px;
	@media only screen and (max-width: 1024px) {
		padding: 60px 20px 35px;
	}
	&__box {
		font-size: 42px;
		width: 100%;
		padding: 1.75em 2em;
		max-width: var(--MainContentMaxWidthPC);
		border: 2px solid var(--colorBlack);
		border-radius: 20px;
		position: relative;
		justify-content: center;
		@media only screen and (max-width: 1024px) {
			font-size: 24px;
			padding: 1.25em 15px 1.25em;
		}
		&__title {
			text-transform: uppercase;
			font-size: 1em;
			position: absolute;
			top: -0.75em;
			margin: auto;
			padding: 0 1.5em;
			background: var(--colorWhite);
			color: var(--colorRed);
			font-weight: 600;
			letter-spacing: 0.125em;
			margin: auto;
			left: 0;
			right: 0;
			width: fit-content;
		}
		.news-box {
			width: 100%;
			font-size: 22px;
			@media only screen and (max-width: 1024px) {
				font-size: 14px;
			}
			&__list {
				line-height: 1.5em;
				padding: 0.5em 0;
				@media only screen and (max-width: 1024px) {
					padding: 0.5em 0;
				}
				&__date {
					color: var(--colorBlack);
					padding: 0.25em 0;
				}
				&__title {
					padding: 0.25em 0;
					line-height: 1.5em;
					color: var(--colorRed);
					text-overflow: ellipsis;
					display: -webkit-box;
					-webkit-box-orient: vertical;
					-webkit-line-clamp: 2;
					overflow: hidden;
				}
				&__content {
					line-height: 1.5em;
					padding: 0.25em 0;
					color: var(--colorRed);
				}
				&__nothing {
					padding: 0.25em 0;
					color: var(--colorBlack);
				}
			}
			&__viewMore {
				width: 50%;
				// display: flex;
				margin: auto;
				position: relative;
				&:hover a {
					color: #fff;
					background-color: #eb4628;
					transition: all 0.3s;
					opacity: 1;
				}
				&:hover .news-box__viewMore_icon {
					transition: all 0.3s;
					content: url("../images/icons/arrowWhite.svg");
				}
				@media only screen and (max-width: 1024px) {
					width: 35%;
				}
				@media screen and (max-width: 599px) {
					width: 80%;
				}
				&_icon {
					position: absolute;
					right: 20px;
					top: 0;
					bottom: 0;
					margin: auto;
					height: fit-content;
					transform: rotate(90deg);
					width: 26.82px;
					height: 20.44px;
					transition: all 0.3s;
					content: url("../images/icons/arrowRed.svg");
					@media screen and (max-width: 1024px) {
						width: 14px;
						height: 10.67px;
					}
				}
				a {
					display: flex;
					color: #eb4628;
					border: solid #eb4628 1.5px;
					width: 100%;
					font-size: 24px;
					border-radius: 100px;
					text-align: center;
					justify-content: center;
					align-items: center;
					font-weight: 500;
					padding: 8px 0;
					transition: all 0.3s;

					@media only screen and (max-width: 1024px) {
						font-size: 16px;
						padding: 4px 0;
					}
				}
			}
		}
	}
}
